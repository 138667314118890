import axios from 'axios';
import { APIServerURL } from '../constants/default-values';

const magazineAPIBaseURL = `${APIServerURL}/magazines`;
const MagazineAPI = {
    getMagazines: () => {
        return axios.get(magazineAPIBaseURL);
    },
    registerMagazine: magazine => {
        const url = `${magazineAPIBaseURL}`;
        return axios.post(url, { ...magazine });
    },
    updateMagazine: (id, magazine) => {
        const url = `${magazineAPIBaseURL}/${id}`;
        return axios.put(url, { ...magazine, id: null });
    },
    deleteMagazine: magazineId => {
        const url = `${magazineAPIBaseURL}/${magazineId}`;
        return axios.delete(url);
    },
    getMagazineSections: (mgzId) => {
        return axios.get(`${magazineAPIBaseURL}/${mgzId}/sections`);
    },
    getMagazineSectionsByKey: (mgzKey) => {
        return axios.get(`${magazineAPIBaseURL}/${mgzKey}/section-by-key`);
    },
    registerMagazineSection: (mgzId, body) => {
        return axios.post(`${magazineAPIBaseURL}/${mgzId}/sections`, body);
    },
    updateMagazineSectionOrders: (body) => {
        return axios.put(`${magazineAPIBaseURL}/sections/orders`, { orders: body });
    },
    updateMagazineSection: (mgzSectionId, body) => {
        return axios.put(`${magazineAPIBaseURL}/sections/${mgzSectionId}`, { ...body, id: null });
    },
    deleteMagazineSection: (mgzSectionId) => {
        return axios.delete(`${magazineAPIBaseURL}/sections/${mgzSectionId}`);
    },
    sendMagazineAPIToClients: (mgzId, emails) => {
        return axios.post(`${magazineAPIBaseURL}/send-email/${mgzId}`, { emails });
    }
}

export default MagazineAPI;
