import axios from 'axios';
import { APIServerURL, ResourceURL } from '../constants/default-values';
import { UploadFiletype } from '../enums/upload';

const UploadAPI = {
    uploadFile: (file, type = UploadFiletype.Magazine) => {
        let suffix = '';
        switch(type) {
            case UploadFiletype.Magazine:
                suffix = 'magazines';
                break;
            case UploadFiletype.Advertisement:
                suffix = 'advertisements';
                break;
            default:
                suffix = 'magazine';
                break;
        }
        const url = `${APIServerURL}/uploads/${suffix}`;
        const form = new FormData();
        form.append('file', file);
        return axios.post(url, form).then(res => `${ResourceURL}/${res.url}`);
    },
}

export default UploadAPI;