import { useLocation } from "react-router";

export function useQuery() {
    const strQuery = useLocation().search;
    if (!strQuery) {
        return [];
    }
    const queries = strQuery.split('?')[1].split('&');
    let objQuery = {};
    for (const query of queries) {
        const parts = query.split('=');
        objQuery = { ...objQuery, [parts[0]]: parts[1] };
    }
    return objQuery;
}
