import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleRight, faAngleDoubleLeft } from '@fortawesome/free-solid-svg-icons'

import { connectMagazineNavigation } from 'common/redux/connects';
import { MagazineSingleColorPalattes } from 'common/constants/magazine';

import './nav-tabs.scss';

function MagazineNavTabs({ goToSpecificPage, sectionsCount, pageSections, height, titles }) {
    const [leftSections, setLeftSections] = useState([]);
    const [rightSections, setRightSections] = useState([]);

    const [leftTabsHidden, setLeftTabsHidden] = useState(true);
    const [rightTabsHidden, setRightTabsHidden] = useState(true);

    useEffect(() => {
        if (sectionsCount > 5) {
            const middleIndex = Math.ceil(sectionsCount / 2);
            setLeftSections(titles.filter(section => section.index < middleIndex));
            setRightSections(titles.filter(section => section.index >= middleIndex));
        } else {
            setLeftSections(titles.map(t => t));
        }
    }, [titles]);

    const onClickSectionTab = (section) => {
        let sectionsSoFar = 0;
        for (let i = 0; i < pageSections.length; i++) {
            const sectionsInPage = pageSections[i];
            sectionsSoFar += sectionsInPage;
            if (sectionsSoFar >= section.index + 1) {
                goToSpecificPage(i);
                break;
            }
        }
    };

    return (<Fragment>
        <div className='tab-wrapper order-1 d-flex flex-row flex-wrap writing-mode-vlr position-relative' style={{ height: height * 0.9 }}>
            <div data-position='left'  className='tabs-hide-wrapper' onClick={() => setLeftTabsHidden(!leftTabsHidden)}>
                <FontAwesomeIcon icon={ leftTabsHidden ? faAngleDoubleLeft : faAngleDoubleRight } />
            </div>
            {leftSections.map(section => <div key={section.id} data-position='left' data-hidden={leftTabsHidden}
                className='flex-shrink-1 flex-grow-1 cursor-pointer magazine-nav-tab px-1 d-flex justify-content-center align-items-center'
                style={{ backgroundColor: MagazineSingleColorPalattes[section.index % 8] }} onClick={() => onClickSectionTab(section)}>
                <div>{section.title}</div>
            </div>)}
        </div>
        {sectionsCount > 5 ? <div className='tab-wrapper order-3 d-flex flex-row flex-wrap writing-mode-vlr position-relative' style={{ height: height * 0.9 }}>
            <div data-position='right' className='tabs-hide-wrapper' onClick={() => setRightTabsHidden(!rightTabsHidden)}>
                <FontAwesomeIcon icon={ rightTabsHidden ? faAngleDoubleRight : faAngleDoubleLeft } />
            </div>
            {rightSections.map(section => <div key={section.id}  data-position='right' data-hidden={rightTabsHidden}
                className='flex-shrink-1 flex-grow-1 cursor-pointer magazine-nav-tab px-1 d-flex justify-content-center align-items-center'
                style={{ backgroundColor: MagazineSingleColorPalattes[section.index % 8] }} onClick={() => onClickSectionTab(section)}>
                <div>{section.title}</div>
            </div>)}
        </div> : null}
    </Fragment>);
};
MagazineNavTabs.propTypes = {
    height: PropTypes.number.isRequired,
    index: PropTypes.number.isRequired,
    titles: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
    }))
};

MagazineNavTabs.defaultProps = {
    height: 0,
    index: 0,
    titles: []
};

export default connectMagazineNavigation(MagazineNavTabs);
