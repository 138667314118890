import axios from 'axios';
import { APIServerURL } from '../constants/default-values';

const adsAPIBaseURL = `${APIServerURL}/advertisements`;
const AdvertisementAPI = {
    getAllAdvertisements: () => {
        return axios.get(`${adsAPIBaseURL}/all`);
    },
    registerAdvertisement: advertisement => {
        const url = `${adsAPIBaseURL}`;
        return axios.post(url, { ...advertisement });
    },
    updateAdvertisement: (advertisement) => {
        const url = `${adsAPIBaseURL}/${advertisement.id}`;
        return axios.put(url, { ...advertisement, id: null });
    },
    deleteAdvertisement: advertisementId => {
        const url = `${adsAPIBaseURL}/${advertisementId}`;
        return axios.delete(url);
    },
}

export default AdvertisementAPI;
