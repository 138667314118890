import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Spinner } from "react-bootstrap";
import { createUseStyles } from "react-jss";

const SpinnerSize = {
  xs: 1,
  sm: 1.2,
  md: 1.4,
  lg: 2,
  xl: 4,
};
const useStyles = createUseStyles({
  loadingSpinner: {
    color: "rgba(0, 0, 0, 0.3)",
    marginRight: 10,
    width: (props) => `${SpinnerSize[props.size]}rem`,
    height: (props) => `${SpinnerSize[props.size]}rem`,
  },
  backdropSpinner: {
    width: `${SpinnerSize.xl}rem`,
    height: `${SpinnerSize.xl}rem`,
    position: "absolute",
    left: `calc(50% - ${SpinnerSize.xl / 2}rem)`,
    top: `calc(50% - ${SpinnerSize.xl / 2}rem)`,
  },
});

export const LoadingSpinner = ({ className, backdrop, size, invisible }) => {
  const classes = useStyles({ size });
  return (
    <Fragment>
      {invisible ? null : backdrop ? (
        <div className={String(`${className} d-flex`).trim()}>
          <Spinner
            variant="primary"
            animation="border"
            className={classes.backdropSpinner}
          />
        </div>
      ) : (<div className={String(`${className} d-inline`).trim()}>
        <Spinner animation="border" className={classes.loadingSpinner} />
      </div>
      )}
    </Fragment>
  );
};
LoadingSpinner.propTypes = {
  backdrop: PropTypes.bool,
  size: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
  invisible: PropTypes.bool,
  className: PropTypes.string
};
LoadingSpinner.defaultProps = {
  backdrop: false,
  size: "md",
  invisible: false,
  className: ''
};

export const PageLoadingSpinner = () => {
  return (
    <div
      className="d-flex"
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "#0000008a",
        zIndex: 10,
      }}
    >
      <Spinner
        animation="border"
        variant="primary"
        className="w-4rem h-4rem m-auto"
      />
    </div>
  );
};
PageLoadingSpinner.propTypes = {};
PageLoadingSpinner.defaultProps = {};
