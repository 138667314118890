import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import { Alert, Button, Form, Row } from "react-bootstrap";
import { withRouter } from "react-router";

import AuthAPI from "common/api/auth";
import { ToastStatus } from "common/enums/toast";
import { connectToasts } from "common/redux/connects";
import { LoadingSpinner } from "components/common/spinner";
import { defaultLoadingOption } from "common/constants/common";

function ResetPasswordPage({ history, match, addToastAction }) {
    const token = match.params.token;
    const [tokenValidated, setTokenValidated] = useState(false);
    const [loadingOption, setLoadingOption] = useState(defaultLoadingOption);

    // Password Form
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [validationError, setValidationError] = useState({
        password: "",
        confirmPassword: "",
    });

    useEffect(() => {
        setLoadingOption({...loadingOption, isLoading: true });
        AuthAPI.verifyResetPasswordToken(token).then(
            (res) => {
                setLoadingOption({...loadingOption, isLoading: false, isInitialized: true });
                setTokenValidated(true);
            },
            (e) => {
                setLoadingOption({...loadingOption, isLoading: false, isInitialized: true });
                setTokenValidated(false);
            }
        );
    }, []);

    const onClickResetPasswordButton = () => {
        const { validationErrors, valid } = validatePasswordForm();
        setValidationError(validationErrors);
        if (valid) {
            setLoadingOption({...loadingOption, isLoading: true, action: 'Reset' });
            AuthAPI.resetPasssword(token, password).then(
                (res) => {
                    setLoadingOption({...loadingOption, isLoading: false, action: '' });
                    addToastAction(
                        `Congrationations`,
                        "You have successfully reset your passwrd.",
                        ToastStatus.Success
                    );
                    history.replace("/auth/login");
                },
                (e) => {
                    setLoadingOption({...loadingOption, isLoading: false, action: '' });
                    addToastAction(
                        `[${e.statusCode}] ${e.error}`,
                        e.message,
                        ToastStatus.Danger
                    );
                }
            );
        }
    };

    const validatePasswordForm = () => {
        let valid = true,
            newValidationError = { password: "", confirmPassword: "" };
        if (!password) {
            newValidationError.password = "Please enter password.";
            valid = false;
        } else if (!confirmPassword) {
            newValidationError.confirmPassword = "Please enter confirm password.";
            valid = false;
        } else if (password !== confirmPassword) {
            newValidationError.confirmPassword =
                "Two passwords are not same. Please check again.";
            valid = false;
        }
        return { valid, validationErrors: newValidationError };
    };

    return ( <
        Row className = "p-5 flex-column justify-content-center m-0 h-100 mx-auto"
        style = {
            { maxWidth: 700 }
        } >
        <
        h3 className = "text-primary mb-5" > Reset your password < /h3> {tokenValidated ? ( <
        Form >
        <
        Form.Group >
        <
        Form.Label > Password < /Form.Label>  <
        Form.Control disabled = { loadingOption.isLoading }
        required type = "password"
        placeholder = "Password"
        value = { password }
        isInvalid = {!!validationError.password }
        onChange = {
            (e) => setPassword(e.target.value)
        }
        />  <
        Form.Control.Feedback type = {!!validationError.password ? "invalid" : "valid" } > { validationError.password } <
        /Form.Control.Feedback>  < /
        Form.Group > <
        Form.Group >
        <
        Form.Label > Confirm Password < /Form.Label> <
        Form.Control disabled = { loadingOption.isLoading }
        required type = "password"
        placeholder = "Password"
        value = { confirmPassword }
        isInvalid = {!!validationError.confirmPassword }
        onChange = {
            (e) => setConfirmPassword(e.target.value)
        }
        />  <
        Form.Control.Feedback type = {!!validationError.confirmPassword ? "invalid" : "valid" } > { validationError.confirmPassword } <
        /Form.Control.Feedback>  < /
        Form.Group > <
        Form.Row className = "justify-between" >
        <
        Button disabled = { loadingOption.isLoading }
        variant = "secondary"
        className = "px-3"
        onClick = {
            () => history.replace("/auth/login")
        } >
        <
        LoadingSpinner invisible = {!loadingOption.isLoading }
        />
        Back <
        /Button>  <
        Button disabled = { loadingOption.isLoading }
        className = "px-3 ml-auto"
        variant = "primary"
        onClick = { onClickResetPasswordButton } >
        <
        LoadingSpinner invisible = { loadingOption.action !== 'Reset' }
        />
        Reset Password <
        /Button>  < /
        Form.Row > <
        /Form>
    ): loadingOption.isLoading ? ( <
        Alert variant = "info" >
        Please wait
        while verify the link is valid. <
        /Alert>) : (  <
        Fragment >
        <
        Alert variant = "danger" >
        Sorry, this link is invalid or expired.Please
        try again. <
        /Alert>  <
        Button variant = "secondary"
        className = "px-3"
        style = {
            { width: "fit-content" }
        }
        onClick = {
            () => history.replace("/auth/login")
        } >
        Back <
        /Button>  < /
        Fragment > )
} <
/Row>);
}

export default withRouter(connectToasts(ResetPasswordPage));