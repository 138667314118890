import React, { Fragment } from 'react';
import {
    Switch,
    Route,
    Redirect,
    withRouter,
    Link
} from 'react-router-dom';
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';

import LandingPage from 'pages/anonymous/landing';
import { connectAuthCheck } from 'common/redux/connects';
import AuthAPI from 'common/api/auth';


function AnonymousLayout({ authenticated, history, userRole, logoutUserAction }) {
    const renderNavItems = () => {
        if (authenticated) {
            return <Fragment>
                <NavDropdown.Item onClick={() => history.push(AuthAPI.getDefaultRedirectPath(userRole))}>Dashboard</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={logoutUserAction}>Logout</NavDropdown.Item>
            </Fragment>
        } else {
            return <Fragment>
                <NavDropdown.Item onClick={() => history.push('/auth/login')}>Login</NavDropdown.Item>
                <NavDropdown.Item onClick={() => history.push('/auth/register')}>Register</NavDropdown.Item>
            </Fragment>
        }
    };

    return (<div className='vh-100 vw-100'>
        <Navbar bg='dark' variant='dark' expand='md' style={{zIndex: 1, boxShadow: "7px 6px 15px 1px #819bff"}}>
            <Navbar.Brand><Link to='/' className='text-decoration-none text-white'>Homly True Predictive Inteligence- Listing Appointment Assurance</Link></Navbar.Brand>
            <Navbar.Toggle />
            <Navbar.Collapse className='justify-content-end'>
                <Nav className='ml-auto mr-3'>
                    <NavDropdown alignRight title={<FontAwesomeIcon className='mx-1 my-auto' icon={ faUser } size='lg' />}>
                        {renderNavItems()}
                    </NavDropdown>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
        <Switch>
            <Route exact path='/' component={LandingPage} />
            <Route path='/' >
                <Redirect to='/' />
            </Route>
        </Switch>
    </div>);
};

export default withRouter(connectAuthCheck(AnonymousLayout));