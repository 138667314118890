import React, { Fragment, useEffect, useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Carousel } from 'react-bootstrap';
import { useSwipeable } from 'react-swipeable';

import MagazineSection from './magazine-section';
import MagazineNavTabs from 'components/magazine/nav-tabs';

import MagazineAPI from 'common/api/magazines';
import { connectMagazine } from 'common/redux/connects';
import { MagazineSectionType } from 'common/enums/magazines';
import { magazineRatio } from 'common/constants/magazine';

import './magazine-carousel.scss';

function MagazineCarousel({
    index, sectionsCount,
    setPages, goToPrevPage, goToNextPage, goToSpecificPage,
    magazineKey, initSectionID }) {
    const [size, setSize] = useState([0, 0]);
    const [sections, setSections] = useState([]);
    const [sectionTitles, setSectionTitles] = useState([]);
    const [pages, updatePages] = useState([]);
    const [isMobile, setIsMobile] = useState(false);
    const [initialized, setInitialized] = useState(false);

    const handlers = useSwipeable({
        onSwipedLeft: (eventData) => goToNextPage(),
        onSwipedRight: (eventData) => goToPrevPage()
    });

    useEffect(() => {
        MagazineAPI.getMagazineSectionsByKey(magazineKey).then(arrSections => {
            setSections(arrSections);
            setSectionTitles(arrSections.map((sec, si) => ({
                id: sec.id,
                index: si,
                title: sec.text1
            })));
        });
    }, []);

    useLayoutEffect(() => {
        const updateCarouselSize = () => {
            setIsMobile(window.innerWidth < 576);
            const widthForTabs = sectionsCount > 5 ? 80 : 40; // 80px is for two slides
            const innerWidth = window.innerWidth, innerHeight = window.innerHeight;
            if (innerWidth >= innerHeight * magazineRatio + widthForTabs) {
                setSize([innerHeight * magazineRatio + widthForTabs, innerHeight]);
            } else {
                setSize([innerWidth, (innerWidth - widthForTabs) / magazineRatio]);
            }
        };
        window.addEventListener('resize', updateCarouselSize);
        updateCarouselSize();
        return () => {
            window.removeEventListener('resize', updateCarouselSize);
        };
    }, []);

    useEffect(() => {
        if (sections.length > 0) {
            isMobile ? displayMagazinesInMobileView() : displayMagazinesInDesktopView();
        }
    }, [isMobile, sections]);

    const displayMagazinesInDesktopView = () => {
        // Place two magazines in a page
        const arrPages = [], arrSectionsPerPage = [];
        for (let i = 0; i < sections.length; i++) {
            const curSection = sections[i];
            if (i === 0) {
                if (curSection.fullWidth) {
                    arrPages.push({ sections: [curSection] });
                } else {
                    arrPages.push({ sections: [{ type: MagazineSectionType.Empty }, curSection] });
                }
                arrSectionsPerPage.push(1);
            } else {
                if (curSection.fullWidth) {
                    arrPages.push({ sections: [curSection] });
                    arrSectionsPerPage.push(1);
                } else {
                    const nextSection = i < sections.length - 1 ? sections[i + 1] : null;
                    if (!nextSection || nextSection.fullWidth) {
                        arrPages.push({ sections: [curSection, { type: MagazineSectionType.Empty }] });
                        arrSectionsPerPage.push(1);
                    } else {
                        arrPages.push({ sections: [curSection, nextSection] });
                        arrSectionsPerPage.push(2);
                        i++;
                    }
                }
            }
        }
        setPages(arrPages.length, sections.length, arrSectionsPerPage);
        if (!initialized && !!initSectionID) {
            const initSectionIndex = sections.findIndex(sec => sec.id === initSectionID);
            if (initSectionIndex >= 0) {
                let sectionsSoFar = 0;
                for (let i = 0; i < arrSectionsPerPage.length; i++) {
                    const sectionsInPage = arrSectionsPerPage[i];
                    sectionsSoFar += sectionsInPage;
                    if (sectionsSoFar >= initSectionIndex + 1) {
                        goToSpecificPage(i);
                        break;
                    }
                }
            }
            setInitialized(true);
        }
        updatePages(arrPages);
    };

    const displayMagazinesInMobileView = () => {
        setPages(sections.length, sections.length, sections.map(s => 1));
        if (!initialized && !!initSectionID) {
            const initSectionIndex = sections.findIndex(sec => sec.id === initSectionID);
            if (initSectionIndex >= 0) {
                goToSpecificPage(initSectionIndex);
            }
            setInitialized(true);
        }
        updatePages(sections);
    };

    return (<Fragment><div className='d-flex justify-content-center align-items-center w-100' {...handlers}>
        {!isMobile ? <Fragment>
            <MagazineNavTabs height={size[1]} titles={sectionTitles} />
            <Carousel touch className='order-2' style={{ maxWidth: size[0], height: size[1], flex: 'auto', overflow: 'hidden' }} activeIndex={index} indicators={false} controls={false}>
                {pages.map((curPage, i) => <Carousel.Item className='mw-100 h-100 mh-100' key={i}>
                    <div className='d-flex h-100 mh-100 mw-100'>
                        {curPage.sections ? curPage.sections.map((section, si) => <MagazineSection key={si} {...section} />) : null}
                    </div>
                </Carousel.Item>)}
            </Carousel>
        </Fragment> :
            <Carousel touch className='w-100 h-100' activeIndex={index} indicators={false} controls={false}>
                {pages.map((curPage, i) => <Carousel.Item className='mw-100 h-100 mh-100' key={i}>
                    <div className='d-flex h-100 mh-100 mw-100'>
                        <MagazineSection {...curPage} />
                    </div>
                </Carousel.Item>)}
            </Carousel>}
    </div>
    </Fragment>);
};

MagazineCarousel.propTypes = {
    magazineKey: PropTypes.string.isRequired,
    initSectionID: PropTypes.string
};

MagazineCarousel.defaultProps = {};

export default connectMagazine(MagazineCarousel);
