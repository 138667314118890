function removeAllNarrPrScriptElements() {
    const elements = document.querySelectorAll('script[data-tag]');
    elements.forEach(element => {
        element.remove();
    })
};

export function getRealEstateWorthy(value) {
    removeAllNarrPrScriptElements();
    var option_script = document.createElement('script');
    option_script.type = 'text/javascript';
    option_script.setAttribute('data-tag', 'narrpr-option');
    option_script.text = `
    var rprAvmWidgetOptions = {
        Token: "661DC122-56B8-4CF1-AA7B-CF9D183FFDE5",
        Query: "${value}",
        CoBrandCode: "btshomly",
        ShowRprLinks: true
    }`;
    document.body.appendChild(option_script);

    var narrpr_script = document.createElement('script');
    narrpr_script.type = 'text/javascript';
    narrpr_script.setAttribute('data-tag', 'narrpr-script');
    narrpr_script.src = '//www.narrpr.com/widgets/avm-widget/widget.ashx/script';
    document.body.appendChild(narrpr_script);
};
