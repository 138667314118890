export const MagazineSectionType = {
    Empty: 'EMPTY',
    Default: 'DEFAULT',
    DefaultButVideo: 'DEFAULT-BUT-VIDEO',
    DefaultButCarousel: 'DEFAULT-BUT-CAROUSEL',
    DefaultButMatterPort: 'DEFAULT-BUT-MATTER-PORT',
    UpsideDown: 'UPSIDE-DOWN',
    UpsideDownButCarousel: 'UPSIDE-DOWN-BUT-CAROUSEL',
    UpsideDownButVideo: 'UPSIDE-DOWN-BUT-VIDEO',
    UpsideDownButMatterPort: 'UPSIDE-DOWN-BUT-MATTER-PORT',
    OnlyCarousel: 'ONLY-CAROUSEL',
    OnlyMatterport: 'ONLY-MATTERPORT',
    OnlyVideo: 'ONLY-VIDEO',
    OnlyImage: 'ONLY-IMAGE',
    OnlyText: 'ONLY-TEXT',
    OnlyVimeo: 'ONLY-VIMEO',
    OnlyYoutube: 'ONLY-YOUTUBE',
    OnlyIframe: 'ONLY-IFRAME',
    OnlySalesComparable: 'ONLY-SALES-COMPARABLE',
    Introduction: 'INTRODUCTION',
    GoodBye: 'GOOD-BYE'
};

export const MagazineNavButtonType = {
    Left: 'LEFT',
    Right: 'RIGHT'
};
