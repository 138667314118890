export const UserRole = {
    Admin: 'ADMIN',
    Agent: 'AGENT',
    Client: 'CLIENT',
};

export const UserGender = {
    Male: 'MALE',
    Female: 'FEMALE',
    Other: 'OTHER',
};
