import React from 'react';
import { Container, Tabs, Tab } from 'react-bootstrap';

import AdminAdsSetting from 'containers/admin/ads-setting';

function AdminSettings() {
    const onChangeTab = (tabName) => {
        console.log(tabName);
    }

    return (<Container fluid>
        <h1 className='mt-2 mb-4'>Admin Settings</h1>
        <Tabs defaultActiveKey='ADMIN-SETTING-ADS' onSelect={onChangeTab}>
            <Tab eventKey='ADMIN-SETTING-ADS' title='Advertisement'>
                <AdminAdsSetting />
            </Tab>
            <Tab eventKey='ADMIN-SETTING-MGZ' title='Magazines'>
                Magazines
            </Tab>
            <Tab eventKey='ADMIN-SETTING-USR' title='User'>
                Users
            </Tab>
        </Tabs>
    </Container>
    );
};

export default AdminSettings;
