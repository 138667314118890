import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';
import { decodeToken } from 'react-jwt';

import AuthAPI from '../../api/auth';
import {
    LOGIN_USER,
    VERIFY_ACCESS_TOKEN,
    LOGOUT_USER,
    LOGIN_ADVERTISEMENT_FINISHED,
} from '../actions/types';

import {
    loginUserSuccess,
    loginUserError,
    loginAdvertisement,
    verifyAccessToken,
    verifyAccessTokenSuccess,
    verifyAccessTokenError,
} from '../actions/auth-actions';
import { addToast } from '../actions/toast-actions';
import { ToastStatus } from '../../enums/toast';
import { UserRole } from '../../enums/auth';

export function* watchLoginUser() {
    yield takeEvery(LOGIN_USER, loginWithEmailPassword);
}

const loginWithEmailPasswordAsync = (email, password) => AuthAPI.signInWithEmailAndPassword(email, password);

const getLoginAdvertisement = () => AuthAPI.getLoginAdvertisement();

function* loginWithEmailPassword({ payload }) {
    const { email, password } = payload.loginUserDto;
    const { history } = payload;
    AuthAPI.removeAccessToken();
    try {
        const accessToken = yield call(loginWithEmailPasswordAsync, email, password);
        const myDecodedToken = decodeToken(accessToken);
        if (myDecodedToken.isEmailVerified === true) {
            yield put(addToast('Login failed', 'You need to verify your email.', ToastStatus.Info));
            // TODO: need to create a new error object
            yield put(loginUserError('Need to verify email'));
        }
        else if (myDecodedToken.role === UserRole.Admin) {
            yield call(AuthAPI.updateAccessToken, accessToken);
            yield put(loginUserSuccess());
            yield put(verifyAccessToken(history));
        } else {
            const { advertisement } = yield call(getLoginAdvertisement);
            if (advertisement) {
                yield put(loginAdvertisement(advertisement, accessToken));
            } else {
                yield call(AuthAPI.updateAccessToken, accessToken);
                yield put(loginUserSuccess());
                yield put(verifyAccessToken(history));
            }
        }
    } catch (error) {
        if (error.statusCode === 401) {
            yield put(addToast('Login failed', 'Incorrect Credential', ToastStatus.Danger));
        } else {
            yield put(addToast('Login failed', 'Please check your internet connection', ToastStatus.Danger));
        }
        yield put(loginUserError(error));
    }
}

export function* watchLoginAdvertisementFinished() {
    yield takeEvery(LOGIN_ADVERTISEMENT_FINISHED, verifyAccessTokenNow);
}

export function* watchVerifyAccessToken() {
    yield takeEvery(VERIFY_ACCESS_TOKEN, verifyAccessTokenNow);
}

const verifyAccessTokenAsync = (tempAccessToken) => AuthAPI.verifyAccessToken(tempAccessToken);

function* verifyAccessTokenNow({ payload }) {
    const { history, forceRedirect } = payload;
    let tempAccessToken = yield select(state => state.authReducer.tempAccessToken);
    try {
        const { user, message } = yield call(verifyAccessTokenAsync, tempAccessToken);
        if (user) {
            yield put(verifyAccessTokenSuccess(user));
            if (forceRedirect) {
                history.replace(AuthAPI.getDefaultRedirectPath());
            }
        } else {
            yield put(verifyAccessTokenError(message));
        }
    } catch (error) {
        yield put(verifyAccessTokenError(error));
    }
}

export function* watchLogoutUser() {
    yield takeEvery(LOGOUT_USER, logout);
}

const logoutAsync = async (history) => {
    // await AuthAPI
    //     .signOut()
    //     .then(succeessResponse => succeessResponse)
    //     .catch(error => error);
    AuthAPI.removeAccessToken();
    history.replace('/');
};

function* logout({ payload }) {
    const { history } = payload;
    try {
        yield call(logoutAsync, history);
    } catch (error) { }
}


export default function* rootSaga() {
    yield all([
        fork(watchLoginUser),
        fork(watchVerifyAccessToken),
        fork(watchLogoutUser),
        fork(watchLoginAdvertisementFinished),
    ]);
}
