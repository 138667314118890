import React, { useEffect, useState, Fragment } from 'react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import Streetview from 'react-google-streetview';

import { googleMapAPIKey } from 'common/constants/common';
import { findPlaceByGoogle } from 'common/utils/google.util';
import { getRealEstateWorthy } from 'common/utils/narrpr.util';
import NarrprAPI from 'common/api/narrpr';
import './landing.scss';

function LandingPage() {
    const [place, setPlace] = useState(null);
    const [pano, setPano] = useState(null);

    useEffect(() => {
        !!place && searchRealEstateWorthy(place);
    }, [place]);

    const searchRealEstateWorthy = () => {
        findPlaceByGoogle(place.label).then(res => {
            const recommendation = res.results[0];
            if (recommendation) {
                const { geometry } = recommendation;
                setPano({
                    position: geometry.location,
                    pov: { heading: 100, pitch: 0 },
                    zoom: 1,
                    scrollwheel: false
                });
                getRealEstateWorthy(place.label);
                NarrprAPI.getPropertyWorthy(place.label);
            }
        }, err => {
            console.log(err);
        });
    };

    return (<div>
        <div className='d-flex justify-content-center align-items-center bg-landing-intro' style={{
            backgroundImage: `url(${'/assets/images/landing/sunset.jpg'})`
        }}>
            <div className='flex-auto google-position-input-wrapper'>
                <GooglePlacesAutocomplete apiKey={googleMapAPIKey} selectProps={{ place, onChange: setPlace }}/>
            </div>
        </div>
        {!!pano ? <Fragment><div style={{ height: 500 }}>
            <Streetview apiKey={googleMapAPIKey} streetViewPanoramaOptions={pano} />
        </div>
        </Fragment> : null}
    </div>);
};

export default LandingPage;
