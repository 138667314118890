import Geocode from 'react-geocode';

Geocode.setApiKey('AIzaSyAka8qQefeZWTHSlCdfR8h1nShmSyNw4d0');
Geocode.setLanguage('en');
Geocode.setRegion('us');
// Geocode.setLocationType('ROOFTOP');

export async function findPlaceByGoogle(value) {
    Geocode.setLocationType('ROOFTOP');
    return new Promise((resolve, reject) => {
        Geocode.fromAddress(value).then(response => {
            resolve(response);
        }, error => {
            reject(error);
        });
    });
};