import React, { Fragment, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { Alert, Button, Col, Container, Form, ListGroup, Row } from "react-bootstrap";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { withRouter } from "react-router";
import arrayMove from "array-move";

import MagazineSection from "components/magazine/magazine-section";
import MagazineForm from "components/magazine/magazine-form";

import { MagazineSectionType } from "common/enums/magazines";
import MagazineAPI from "common/api/magazines";
import { connectToasts } from "common/redux/connects";
import { ToastStatus } from "common/enums/toast";
import { magazineRatio } from "common/constants/magazine";
import { LoadingSpinner } from "components/common/spinner";
import { defaultLoadingOption } from "common/constants/common";
import { useQuery } from "common/hooks/routes.hook";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faArrowLeft } from '@fortawesome/free-solid-svg-icons'

const initialMgzSection = {
    type: MagazineSectionType.Default,
    order: 0,
    text1: "Default Title",
    text2: "Default Description",
    asset: "",
    assets: [],
    fullWidth: false,
};

function ClientMagazineSections({ match, history, addToastAction }) {
    const magazineId = match.params.magazineId;
    const routeQuery = useQuery();

    const [sections, setSections] = useState([]);
    const [sectionOdds, setSectionOdds] = useState([]);

    const [curSection, setCurSection] = useState(null);
    const [prevSection, setPrevSection] = useState(null);
    const [isMobile, setIsMobile] = useState(false);
    const [nextSection, setNextSection] = useState(null);

    const [loadingOption, setLoadingOption] = useState(defaultLoadingOption);

    const [visiblePreview, setVisiblePreview] = useState(true);

    // Responsive View
    const [previewWidth, setPreviewWidth] = useState(0);
    const previewRef = useRef(null);

    useEffect(() => {
        setLoadingOption({ ...loadingOption, isLoading: true });
        MagazineAPI
            .getMagazineSections(magazineId)
            .then((arrSections) => {
                setSections(arrSections);
                setCurSection(arrSections[0] || null);
                setLoadingOption({ ...loadingOption, isLoading: false, isInitialized: true });
            }, (e) => {
                setLoadingOption({ ...loadingOption, isLoading: false, isInitialized: true });
                addToastAction(`[${e.statusCode}] ${e.error}`, e.message, ToastStatus.Danger);
            });
    }, []);

    useEffect(() => {
        updatePreviewSize(); updateAroundSections();
    }, [curSection]);

    useEffect(() => {
        if (sections.length < 1) {
            setSectionOdds([]);
            return;
        }
        const arrSectionOdds = []; for (let i = 0; i < sections.length; i++) {
            const curSection = sections[i];
            if (i === 0) {
                arrSectionOdds.push({ isOdd: true, count: 2 });
            } else {
                let prevSectionNumber = 0;
                arrSectionOdds.forEach(so => prevSectionNumber += so.count);
                const left = prevSectionNumber % 4;
                switch (left) {
                    case 0:
                        arrSectionOdds.push({ isOdd: true, count: curSection.fullWidth ? 2 : 1 });
                        break;
                    case 1:
                        if (curSection.fullWidth) {
                            arrSectionOdds[i - 1].count = 2;
                            arrSectionOdds.push({ isOdd: false, count: 2 });
                        } else {
                            arrSectionOdds.push({ isOdd: true, count: 1 });
                        }
                        break;
                    case 2:
                        arrSectionOdds.push({ isOdd: false, count: curSection.fullWidth ? 2 : 1 });
                        break;
                    case 3:
                        if (curSection.fullWidth) {
                            arrSectionOdds[i - 1].count = 2;
                            arrSectionOdds.push({ isOdd: true, count: 2 });
                        } else {
                            arrSectionOdds.push({ isOdd: false, count: 1 });
                        }
                        break;
                    default:
                        break;
                }
            }
        }
        setSectionOdds(arrSectionOdds);
    }, [sections]);

    useLayoutEffect(() => {
        window.addEventListener("resize", updatePreviewSize);
        updatePreviewSize(); return () => {
            window.removeEventListener("resize", updatePreviewSize);
        };
    }, []);

    const updatePreviewSize = (times = 1) => {
        setIsMobile(window.innerWidth < 576);
        if (previewRef && previewRef.current) {
            const newWidth = parseInt(previewRef.current.offsetHeight / magazineRatio * times);
            if (newWidth !== NaN) {
                setPreviewWidth(newWidth);
            }
        }
    };

    const updateAroundSections = () => {
        if (isMobile) {
            setPrevSection(null);
            setNextSection(null);
            return;
        }
        const index = sections.findIndex(sec => sec.id === curSection.id);
        if (index <= 0) {
            setPrevSection(null);
            setNextSection(null);
        } else if (sectionOdds[index].count === 2) {
            setPrevSection(null);
            setNextSection(null);
        } else {
            if (sectionOdds[index].isOdd === sectionOdds[index - 1].isOdd) {
                setPrevSection(sections[index - 1]);
                setNextSection(null);
            } else if (index + 1 < sectionOdds.length && sectionOdds[index].isOdd === sectionOdds[index + 1].isOdd) {
                setPrevSection(null);
                setNextSection(sections[index + 1]);
            } else {
                setPrevSection(null);
                setNextSection(null);
            }
        }
    };

    const onClickNewMgzSectionButton = () => {
        setSections(sections.concat({ ...initialMgzSection, id: `not-saved-${Date.now()}`, order: sections.length }));
    };

    const onSaveMagazine = () => {
        if (String(curSection.id).includes("not-saved-")) {
            setLoadingOption({ ...loadingOption, isLoading: true, action: 'Create' });
            MagazineAPI
                .registerMagazineSection(magazineId, { ...curSection, id: null })
                .then((res) => {
                    const arrSections = sections.map((s) => s.id === curSection.id ? { ...curSection, id: res.id } : s);
                    setSections(arrSections);
                    setCurSection({ ...curSection, id: res.id });
                    addToastAction(`Congrationations`, "You have successfully registerd a new section.", ToastStatus.Success);
                    setLoadingOption({ ...loadingOption, isLoading: false, action: '' });
                }, (e) => {
                    setLoadingOption({ ...loadingOption, isLoading: false, action: '' });
                    addToastAction(`[${e.statusCode}] ${e.error}`, e.message, ToastStatus.Danger);
                });
        } else {
            setLoadingOption({ ...loadingOption, isLoading: true, action: 'Update' });
            MagazineAPI
                .updateMagazineSection(curSection.id, curSection)
                .then((res) => {
                    const arrSections = sections.map((s) => s.id === curSection.id ? curSection : s);
                    const updatedIndex = sections.findIndex(s => s.id === curSection.id);
                    setSections(updateFullWidthOption(arrSections, updatedIndex));
                    addToastAction(`Congrationations`, "You have successfully update a section.", ToastStatus.Success);
                    setLoadingOption({ ...loadingOption, isLoading: false, action: '' });
                }, (e) => {
                    setLoadingOption({ ...loadingOption, isLoading: false, action: '' });
                    addToastAction(`[${e.statusCode}] ${e.error}`, e.message, ToastStatus.Danger);
                });
        }
    };

    const updateFullWidthOption = (arrSections = [], uIndex = -1) => {
        if (!arrSections || arrSections.length < 1 || uIndex < 0) {
            return arrSections;
        }
        for (let i = uIndex + 1; i < arrSections.length; i++) {
            arrSections[i].fullWidth = false;
        }
        return arrSections;
    };

    const onRemoveSection = () => {
        if (String(curSection.id).includes("not-saved-")) {
            const arrNewSections = sections.filter((section) => section.id !== curSection.id);
            setSections(arrNewSections);
            setCurSection(arrNewSections[0] || null);
            addToastAction(`Congrationations`, "You have successfully removed a section.", ToastStatus.Success);
        } else {
            setLoadingOption({ ...loadingOption, isLoading: true, action: 'Delete' });
            MagazineAPI
                .deleteMagazineSection(curSection.id)
                .then((res) => {
                    const arrNewSections = sections.filter((section) => section.id !== curSection.id);
                    setSections(arrNewSections);
                    setCurSection(arrNewSections[0] || null);
                    addToastAction(`Congrationations`, "You have successfully removed a section.", ToastStatus.Success);
                    setLoadingOption({ ...loadingOption, isLoading: false, action: '' });
                }, (e) => {
                    setLoadingOption({ ...loadingOption, isLoading: false, action: '' });
                    addToastAction(`[${e.statusCode}] ${e.error}`, e.message, ToastStatus.Danger);
                }
                );
        }
    };

    const onPreview = () => {
        const baseURL = `/publish/magazine/${routeQuery.url || magazineId}`; window.open(baseURL, '_blank');
    };

    const onPreviewSpecificSection = () => {
        const baseURL = `/publish/magazine/${routeQuery.url || magazineId}`;
        const query = `initSection=${curSection.id}`;
        window.open(`${baseURL}?${query}`, '_blank');
    };

    const SortableMgzSectionItem = SortableElement(({ section, si }) =>
        <ListGroup.Item action
            active={
                curSection && curSection.id === section.id} onClick={
                    () => setCurSection(section)
                }
            variant={
                'dark'
            } style={{backgroundColor: "#762c7a", border: 'none'}}>
            <p className="mb-0 cut-text" > {si + 1}. &nbsp; &nbsp; &nbsp; {section.text1} </p>
        </ListGroup.Item>
    );

    const SortableMgzSectionContainer = SortableContainer(({ sections }) =>
        <ListGroup >
            {
                sections.map((section, i) =>
                    <SortableMgzSectionItem key={`mgzsection-${i}`}
                        index={i}
                        si={i}
                        section={section}
                    />
                )
            }
        </ListGroup>
    );
    const onMgzSectionsSortEnded = ({ oldIndex, newIndex }) => {
        if (sections[oldIndex].id.includes('not-saved-')) {
            addToastAction('Info', 'Please create section before set order.', ToastStatus.Info);
            return;
        }
        const newSections = arrayMove(sections, oldIndex, newIndex);
        for (let i = 0; i < newSections.length; i++) {
            newSections[i].order = i;
        }
        if (oldIndex !== newIndex) {
            setLoadingOption({ ...loadingOption, isLoading: true, action: '' });
            MagazineAPI.updateMagazineSectionOrders(
                newSections.map(ns => ({ id: ns.id, order: ns.order })
                ))
                .then(res => {
                    setSections(newSections);
                    setLoadingOption({ ...loadingOption, isLoading: false, action: '' });
                }, (e) => {
                    setLoadingOption({ ...loadingOption, isLoading: false, action: '' });
                    addToastAction(`[${e.statusCode}] ${e.error}`, e.message, ToastStatus.Danger);
                });
        }
    };
    const checkIfFullWithDisabled = useMemo(() => {
        const curIndex = sections.findIndex(s => s.id === curSection.id);
        if (curIndex <= 0) {
            return false;
        }
        const curSectionOdds = sectionOdds[curIndex];
        const prevSectionOdds = sectionOdds[curIndex - 1];
        if (curSectionOdds.isOdd !== prevSectionOdds.isOdd) {
            return false;
        }
        return true;
    }, [curSection]);

    return (
        <main className="container mt-3 magazine-overflow">
            <Container fluid className="py-4" >
                <Row className="mt-3 justify-content-between" >
                    <Col sm={12} >
                        <Button variant="link" onClick={
                            () => history.goBack()} >
                            <FontAwesomeIcon className='ml-auto cursor-pointer' icon={faArrowLeft} />
                        </Button>
                        <Button className="float-right" variant="link" onClick={onPreview} >
                            <FontAwesomeIcon className='ml-auto cursor-pointer' icon={faEye} />
                        </Button>
                    </Col>
                </Row>
                <Row className="my-3" >
                    <Col>
                        <h1 className="ml-2 text-center text-white page-title" > Magazine Contents </h1>
                    </Col>
                </Row>
                <Row >
                    {!loadingOption.isInitialized ?
                        <LoadingSpinner className='mx-auto' size='xl' /> : sections.length < 1 ?
                            <Alert variant='info' className='mx-auto' > No magazine sections created yet. </Alert> : null
                    }
                </Row>
                <Row className='justify-content-center' > {visiblePreview ?
                    <Fragment>
                        <Col className='d-flex justify-content-center' >
                            {!!prevSection && !isMobile ?
                                <div ref={previewRef} className="d-block overflow-hidden" style={{ width: previewWidth, height: 400 }} >
                                    <MagazineSection {...prevSection} preview />
                                </div> : null}
                            {!!curSection ?
                                <div ref={previewRef} className="border border-primary d-block overflow-hidden card-box" style={{ width: curSection.fullWidth ? 2 * previewWidth : previewWidth, height: 400 }} >
                                    <MagazineSection {...curSection} preview />
                                </div> : null}
                            {
                                !!nextSection && !isMobile ?
                                    <div ref={previewRef} className="d-block overflow-hidden" style={{ width: previewWidth, height: 400 }} >
                                        <MagazineSection {...nextSection} preview />
                                    </div> : null}
                        </Col>
                    </Fragment> : null}
                </Row>
                <Row className="mt-3" >
                    <Col md={4} >
                        <Row >
                            <Col sm={12} className='mb-2' >
                                <Button disabled={loadingOption.isLoading} className="float-right" variant="success" onClick={onClickNewMgzSectionButton} >
                                    New section
                                </Button>
                            </Col>
                            <Col sm={12} className='mb-2' >
                                <SortableMgzSectionContainer sections={sections} pressDelay={200} onSortEnd={onMgzSectionsSortEnded} />
                            </Col>
                            <Col sm={12} >
                                <Alert variant='secondary' >
                                    Click and hold momentarily in order to rearrange sections.
                                </Alert>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={8} >
                        {
                            !curSection ? null : (
                                <Fragment >
                                    <Row className="justify-content-end mx-0 mb-2" >
                                        <Button className='mr-1' variant="success" onClick={
                                            () => { setVisiblePreview(!visiblePreview) }} >
                                            {visiblePreview ?
                                                'Hide Preview' : 'Show Preview'}
                                        </Button>
                                        <Button disabled={loadingOption.isLoading} variant="success" className='mr-auto' onClick={onPreviewSpecificSection} >

                                            View this Section
                                        </Button>
                                        <Button disabled={loadingOption.isLoading} variant="primary" onClick={onSaveMagazine} >
                                            <LoadingSpinner invisible={loadingOption.action !== 'Create' && loadingOption.action !== 'Update'} />
                                            {String(curSection.id).includes("not-saved-") ? "Create" : "Update"}
                                        </Button>
                                        <Button disabled={loadingOption.isLoading} variant="danger" className="ml-1" onClick={onRemoveSection} >
                                            <LoadingSpinner invisible={loadingOption.action !== 'Delete'} />
                                            Remove
                                        </Button>
                                    </Row>
                                    <Form>
                                        <MagazineForm setDisable={(l) => setLoadingOption({ ...loadingOption, isLoading: l })} fullWidthDisabled={checkIfFullWithDisabled} disabled={loadingOption.isLoading} option={curSection} onChange={setCurSection} />
                                    </Form>
                                </Fragment>)
                        }
                    </Col>
                </Row>
            </Container>
        </main>);
}
ClientMagazineSections.propTypes = {}; ClientMagazineSections.defaultProps = {};

export default withRouter(connectToasts(ClientMagazineSections));