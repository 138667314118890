import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'

import { MagazineNavButtonType } from 'common/enums/magazines';
import './nav-button.scss'

function MagazineNavButton({ position, onClick }) {
    return (
        <div className={classNames(['position-fixed', 'cursor-pointer', 'rounded-circle', 'magazine-nav-button'], {
            'magazine-nav-button-left': position === MagazineNavButtonType.Left,
            'magazine-nav-button-right': position === MagazineNavButtonType.Right })} onClick={() => onClick()}>
            <FontAwesomeIcon icon={ position === MagazineNavButtonType.Right ? faChevronRight : faChevronLeft } size='lg' />
        </div>
    );
};
MagazineNavButton.propTypes = {
    position: PropTypes.oneOf(Object.values(MagazineNavButtonType)),
    onClick: PropTypes.func
};

MagazineNavButton.defaultProps = {
    position: MagazineNavButtonType.Left,
    onClick: () => { console.log('Magazine Nav Button Clicked!!!'); }
};

export default MagazineNavButton;
