import React from 'react';
import { withRouter } from 'react-router';
import { useQuery } from "common/hooks/routes.hook";

import MagazineCarousel from 'components/magazine/magazine-carousel';
// import MagazineNavBar from 'components/magazine/nav-bar';
import MagazineNavButtons from 'components/magazine/nav-buttons';

function PublishedMagazine({ match }) {
    const mgzKey = match.params.magazineKey;
    const routeQuery = useQuery();

    return (
        <div className='vw-100 vh-100 bg-dark d-flex justify-content-center'>
            <MagazineCarousel magazineKey={mgzKey} initSectionID={routeQuery.initSection}/>
            {/* <MagazineNavBar /> */}
            <MagazineNavButtons position='bottom' />
        </div>
    );
};

export default withRouter(PublishedMagazine);
