import React from 'react';
import { Switch, Route, } from 'react-router-dom';

import PublishedMagazine from 'pages/publish/magazine';

function PublishMagazineLayout() {
    return (<div className='vh-100 vw-100'>
        <Switch>
            <Route exact path='/publish/magazine/:magazineKey' component={PublishedMagazine} />
        </Switch>
    </div>);
};

export default PublishMagazineLayout;