import axios from 'axios';
import querystring from 'query-string';
import { isExpired, decodeToken } from "react-jwt";

import { APIServerURL, ConstantNames } from '../constants/default-values';
import { AdvertisementType } from '../enums/advertisements';
import { UserRole } from '../enums/auth';

const AuthAPI =  {
    signInWithEmailAndPassword: (email, password) => {
        const url = `${APIServerURL}/auth/login`;
        return axios.post(url, { email, password }).then(res => res.accessToken);
    },
    getLoginAdvertisement: () => {
        const query = querystring.stringify({
            type: AdvertisementType.Video,
            take: 1,
            keyword: 'login'
        });
        const url = `${APIServerURL}/advertisements?${query}`;
        return axios.get(url).then(advertisements => ({
            advertisement: advertisements && advertisements.length > 0 ? advertisements[0] : null
        }));
    },
    verifyAccessToken: (tempAccessToken = '') => {
        if (tempAccessToken) {
            AuthAPI.updateAccessToken(tempAccessToken);
        }
        if (!AuthAPI.getAccessToken()) {
            return;
        }
        const url = `${APIServerURL}/auth/profile`;
        return axios.get(url).then(user => ({ user }), e => e);
    },
    registerClient: (registerUserDto) => {
        const url = `${APIServerURL}/auth/register`;
        return axios.post(url, { ...registerUserDto, role: UserRole.Client });
    },
    signOut: () => {
        AuthAPI.removeAccessToken();
    },
    forgotPasssword: (email) => {
        const url = `${APIServerURL}/auth/forgot-password`;
        return axios.post(url, { email });
    },
    verifyResetPasswordToken: (token) => {
        const url = `${APIServerURL}/auth/verify-reset-password-token/${token}`;
        return axios.get(url);
    },
    resetPasssword: (token, password) => {
        const url = `${APIServerURL}/auth/reset-password`;
        return axios.put(url, { token, password });
    },
    verifyEmail: (token) => {
        const url = `${APIServerURL}/auth/verify-email`;
        return axios.put(url, { token });
    },
    getDefaultRedirectPath: () => {
        const accessToken = AuthAPI.getAccessToken();
        const myDecodedToken = decodeToken(accessToken);
        const isTokenExpired = isExpired(accessToken);

        if (isTokenExpired) {
            // TODO: do something
        }
        const userRole = myDecodedToken ? myDecodedToken.role : null;
        if (!userRole) return '/';
        return `/${String(userRole).toLowerCase()}`;
    },
    getAccessToken: () => {
        return localStorage.getItem(ConstantNames.AccessToken);
    },
    updateAccessToken: at => {
        localStorage.setItem(ConstantNames.AccessToken, at)
    },
    removeAccessToken: () => {
        localStorage.removeItem(ConstantNames.AccessToken);
    }
}

export default AuthAPI;
