import React, { useState } from 'react';
import { Button, Form, Row } from 'react-bootstrap';
import { withRouter } from 'react-router';

import AuthAPI from 'common/api/auth';
import { ToastStatus } from 'common/enums/toast';
import { connectToasts } from 'common/redux/connects';
import { validateEmail } from 'common/utils/common.utils';
import { LoadingSpinner } from 'components/common/spinner';
import { defaultLoadingOption } from 'common/constants/common';

function ForgotPasswordPage({ history, addToastAction }) {
    const [email, setEmail] = useState('');
    const [loadingOption, setLoadingOption] = useState(defaultLoadingOption);

    const onClickForgotPasswordButton = () => {
        // Email Validation
        if (!email) {
            addToastAction(`Warning`, 'Please type your email.', ToastStatus.Warning);
            return;
        } else if (!validateEmail(email)) {
            addToastAction(`Warning`, 'Please type correct email.', ToastStatus.Warning);
            return;
        }

        setLoadingOption({ ...loadingOption, action: 'Forgot', isLoading: true });
        AuthAPI.forgotPasssword(email).then(res => {
            setLoadingOption({ ...loadingOption, action: '', isLoading: false });
            addToastAction(`Congrationations`, 'You have successfully requested to reset password. Please check your email.', ToastStatus.Success);
            history.replace('/auth/login');
        }, e => {
            setLoadingOption({ ...loadingOption, action: '', isLoading: false });
            addToastAction(`[${e.statusCode}] ${e.error}`, e.message, ToastStatus.Danger);
        });
    };

    return (<Row className='p-5 flex-column justify-content-center m-0 h-100 mx-auto' style={{ maxWidth: 700 }}>
        <h3 className='text-primary mb-5'>Did you forget your password?</h3>
        <Form>
            <Form.Group controlId='formBasicEmail'>
                <Form.Label>Email address</Form.Label>
                <Form.Control disabled={loadingOption.isLoading} type='email' placeholder='Enter email' value={email} onChange={e => setEmail(e.target.value)} />
                <Form.Text className='text-muted'>We'll send link to your email.</Form.Text>
            </Form.Group>

            <Form.Row className='justify-content-between'>
                <Button disabled={loadingOption.isLoading} className='px-3' variant='secondary' onClick={() => history.goBack()}>
                    Go back to Login
                </Button>
                <Button disabled={loadingOption.isLoading} className='px-3' variant='primary' onClick={onClickForgotPasswordButton}>
                    <LoadingSpinner invisible={loadingOption.action !== 'Forgot'}/>
                    Submit
                </Button>
            </Form.Row>
        </Form>
    </Row>);
};

export default withRouter(connectToasts(ForgotPasswordPage));
