export const capitalizeSentence = (sentence, forEachWord = false) => {
    if (forEachWord) {
        return sentence.split(' ').map(eachWord => capitalizeWord(eachWord)).join(' ');
    } else {
        return capitalizeWord(sentence);
    }
};

export const capitalizeWord = (word) => {
    return String(word[0]).toUpperCase() + String(word.substring(1)).toLowerCase();
};

export const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
};

export const validateDomain = (url) => {
    const re = /^((?:http:\/\/)|(?:https:\/\/))(www.)?((?:[a-zA-Z0-9]+\.[a-z]{3})|(?:\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}(?::\d+)?))([/a-zA-Z0-9.]*)$/;
    return re.test(String(url).toLowerCase());
};
