import axios from 'axios';
import { APIServerURL } from '../constants/default-values';

const alternativesAPIBaseURL = `${APIServerURL}/alternatives`;
const AlternativesAPI = {
    getAllternatives: () => {
        return axios.get(`${alternativesAPIBaseURL}`);
    },
    registerAlternative: alternative => {
        const url = `${alternativesAPIBaseURL}`;
        return axios.post(url, { ...alternative });
    },
    updateAlternative: (alternative) => {
        const url = `${alternativesAPIBaseURL}/${alternative.id}`;
        return axios.put(url, { ...alternative, id: null });
    },
    deleteAlternative: alternativeId => {
        const url = `${alternativesAPIBaseURL}/${alternativeId}`;
        return axios.delete(url);
    },
    getAllCategories: () => {
        return axios.get(`${alternativesAPIBaseURL}/categories`);
    },
    registerCategory: (body) => {
        return axios.post(`${alternativesAPIBaseURL}/categories`, body);
    },
    deleteCategory: (categoryId) => {
        return axios.delete(`${alternativesAPIBaseURL}/categories/${categoryId}`);
    },
    updateComparison: (body) => {
        return axios.put(`${alternativesAPIBaseURL}/categories`, body);
    }
}

export default AlternativesAPI;
