import React from 'react';
import {
    Switch,
    Route,
    Redirect,
    withRouter
} from 'react-router-dom';
import RegisterPage from 'pages/auth/register';
import { Col, Image, Row } from 'react-bootstrap';

import LandingPage from 'pages/anonymous/landing';
import LoginPage from 'pages/auth/login';
import ForgotPasswordPage from 'pages/auth/forgot-password';
import ResetPasswordPage from 'pages/auth/reset-password';
import EmailValidationPage from 'pages/auth/email-validation';

function AuthLayout({ history }) {
    return (<Row style={{ flex: '1 0 auto' }}>
        <Col md={6} lg={5} className='d-flex' style={{ backgroundColor: '#F4F2F9' }}>
            <Row className='justify-content-between flex-column m-0 w-100'>
                <Col className='text-center p-0 flex-auto mt-100 mb-100 px-5'>
                    <Image alt='Logo' src='/assets/images/logo-lg.png' className='mb-4 cursor-pointer position-absolute'
                        onClick={() => history.replace('/')} style={{ height: 100, left: 50, top: -50 }} />
                    <h1 className='mt-100'>The Only Predictive Listing Presentation App</h1>
                    <h1 className='text-primary mt-3'><b>Like Magic Your Next Slide Will Be Your Clients Last Sale Objection.</b></h1>
                </Col>
                <Col className='px-30 text-center'>
                    {/* <Image alt='House' src='/assets/images/auth/house.png' className='position-absolute' style={{ left: 'calc(50% - 150px)', top: -150, width: 300, maxWidth: '50%' }}/> */}
                    <Image alt='User' src='/assets/images/auth/user.png' className='position-absolute' style={{ right: -40, bottom: 10, width: 350, maxWidth: '80%' }}/>
                    <Image alt='Real Estate' src='/assets/images/auth/house-bg-3.png' className='mb-4 w-100' style={{ maxWidth: 700 }}/>
                </Col>
            </Row>
        </Col>
        <Col md={6} lg={7} className='p-0'>
            <Switch>
                <Route exact path='/' component={LandingPage} />
                <Route path='/auth' >
                    <Switch>
                        <Route exact path='/auth/login' component={LoginPage} />
                        <Route exact path='/auth/forgot-password' component={ForgotPasswordPage} />
                        <Route exact path='/auth/reset-password/:token' component={ResetPasswordPage} />
                        <Route exact path='/auth/validate-email/:token' component={EmailValidationPage} />
                        <Route exact path='/auth/register' component={RegisterPage} />
                    </Switch>
                </Route>
                <Route path='/' >
                    <Redirect to='/' />
                </Route>
            </Switch>
        </Col>
    </Row>);
};

export default withRouter(AuthLayout);