import React, { Fragment, useEffect } from 'react';
import {
  Switch,
  Route,
  Redirect,
  withRouter
} from 'react-router-dom';

// Layouts
import AdminLayout from './layouts/admin';
import AgentLayout from './layouts/agent';
import ClientLayout from './layouts/client';
import AuthLayout from './layouts/auth';
import AnonymousLayout from './layouts/anonymous';
import PublishMagazineLayout from './layouts/publish';

import ToastList from './components/common/toast-list';
import { PageLoadingSpinner } from './components/common/spinner';
import { connectAuthCheck } from './common/redux/connects';
import { UserRole } from './common/enums/auth';

function App({ history, tokenVerified, authenticated, userRole, verifyAccessTokenAction }) {
  useEffect(() => {
    verifyAccessTokenAction(history, false);
  }, []);

  const renderApp = () => (<Fragment>
    <ToastList />
    <Switch>
      <Route path='/admin'>{authenticated && userRole === UserRole.Admin ? <AdminLayout /> : <Redirect to='/auth/login' />}</Route>
      <Route path='/agent'>{authenticated && (userRole === UserRole.Agent) ? <AgentLayout /> : <Redirect to='/auth/login' />}</Route>
      <Route path='/client'>{authenticated && (userRole === UserRole.Client) ? <ClientLayout /> : <Redirect to='/auth/login' />}</Route>
      <Route path='/publish' component={PublishMagazineLayout} />
      <Route path='/auth'>{!authenticated ? <AuthLayout /> : <Redirect to='/' />}</Route>
      <Route path='/' component={AnonymousLayout} />
    </Switch>
  </Fragment>);

  return (
    <Fragment>
      { !tokenVerified ? <PageLoadingSpinner /> : renderApp()}
    </Fragment>

  );
}

export default withRouter(connectAuthCheck(App));
