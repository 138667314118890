import React from 'react';
import { NavDropdown } from 'react-bootstrap';
import { Switch, Route, Redirect, withRouter, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons'

import ClientMagazines from 'pages/client/magazines';
import ClientMagazineSections from 'pages/client/magazine-sections';
import { connectAuth } from 'common/redux/connects';
import { CustomSwitch } from 'components/common/switch';
import { isDarkTheme, toggleDarkTheme } from 'common/utils/theme.utils';
import Logo from "../assets/svg/logo.svg";
import Label from "../assets/svg/logo-label.svg";
import "../assets/scss/client.scss";


const  ThemeContext = React.createContext()

function ClientLayout({ match, logoutUserAction }) {
    return (
        <div className='client-container'>
            <div className='header-bar py-3'>
                <div className='header-content mx-auto d-flex justify-content-between'>
                    <div>
                        <Link to='/' className='text-decoration-none'>{<img src={Logo} alt="logo" />}{<img src={Label} alt="logo-label" />} </Link>
                    </div>

                    <div className="dropdown d-flex align-items-center">
                        <CustomSwitch className='switch' value={isDarkTheme()} onChange={e => toggleDarkTheme(e.target.checked)} />
                        <NavDropdown alignRight title={<FontAwesomeIcon className='' icon={faUser} size='lg' />}>
                            <NavDropdown.Item disabled>Profile</NavDropdown.Item>
                            <NavDropdown.Item disabled>Magazines</NavDropdown.Item>
                            <NavDropdown.Item disabled>Notifications</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item onClick={logoutUserAction}>Logout</NavDropdown.Item>
                        </NavDropdown>
                    </div>
                </div>
            </div>
            <div className='main-content'>
                <Switch>
                    <Route exact path={`${match.url}/dashboard`}>
                        <div>Customer Dashboard</div>
                    </Route>
                    <Route exact path={`${match.url}/magazines`} component={ClientMagazines} />
                    <Route exact path={`${match.url}/magazines/:magazineId/sections`} component={ClientMagazineSections} />
                    <Route path='/' >
                        <Redirect to={`${match.url}/magazines`} />
                    </Route>
                </Switch>
            </div>
        </div>
    );
};

export default withRouter(connectAuth(ClientLayout));