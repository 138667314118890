import axios from 'axios';
import { APIServerURL } from '../constants/default-values';
import { DataTreeSearchType } from '../enums/datatree';

const datatreeAPIBaseURL = `${APIServerURL}/datatree`;
const DatatTreeAPI = {
    getSalesComparablesByAddress: (addressDetail = {}) => {
        return axios.post(`${datatreeAPIBaseURL}/get-report/sales-comparable`, {
            SearchType: DataTreeSearchType.Address,
            AddressDetail: addressDetail
        });
    },
}

export default DatatTreeAPI;
