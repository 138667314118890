import React from 'react';
import { Container } from 'react-bootstrap';
import { withRouter } from 'react-router';

function AdminUsers() {
    return (<Container fluid>
        <h1>Admin Users</h1>
    </Container>
    );
};

export default withRouter(AdminUsers);
