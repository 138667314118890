import React, { useEffect, useState } from 'react';
import { Alert, Button, Row } from 'react-bootstrap';
import { withRouter } from 'react-router';

import AuthAPI from 'common/api/auth';

function EmailValidationPage({ history, match }) {
  const token = match.params.token;
  const [verified, setVerified] = useState(null);

  useEffect(() => {
    AuthAPI.verifyEmail(token).then(
      (res) => {
        setVerified(true);
      },
      (e) => setVerified(false)
    );
  }, []);

  const onClickContinue = () => {
    const redirectPath = AuthAPI.getDefaultRedirectPath();
    if (redirectPath === '/') {
      history.replace('/auth/login');
    } else {
      history.replace(redirectPath);
    }
  };

  return (
    <Row
      className='p-5 flex-column justify-content-center m-0 h-100 mx-auto'
      style={{ maxWidth: 700 }}
    >
      <h3 className='text-primary mb-5'>Validate Email</h3>
      {verified === null ? (
        <Alert variant='info'>Please wait while verifying your email.</Alert>
      ) : verified === false ? (
        <Alert variant='danger'>
          Sorry, this link is invalid or expired. Please try again.
        </Alert>
      ) : (
        <Alert variant='success'>
          Your email has been successfully verified.
        </Alert>
      )}
      <Button
        variant='secondary'
        className='px-3'
        style={{ width: 'fit-content' }}
        onClick={onClickContinue}
      >
        Continue
      </Button>
    </Row>
  );
}

export default withRouter(EmailValidationPage);
