import React, { Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'

import { connectMagazineNavigation } from 'common/redux/connects';
import MagazineNavButton from './nav-button';
import { MagazineNavButtonType } from 'common/enums/magazines';
import './nav-buttons.scss'

function MagazineNavButtons({ position, goToPrevPage, goToNextPage }) {
    return (
        <Fragment>
            {position === 'side' ? <div>
                <MagazineNavButton position={MagazineNavButtonType.Left} onClick={goToPrevPage} />
                <MagazineNavButton position={MagazineNavButtonType.Right} onClick={goToNextPage} />
            </div> : null}
            {position === 'bottom' ? <div className='position-fixed magazine-nav-buttons-wrapper d-flex'>
                <div className="magazine-nav-inner-button flex-shrink-1 flex-grow-1 text-center cursor-pointer" onClick={goToPrevPage} >
                    <FontAwesomeIcon icon={ faChevronLeft } size='lg' />
                </div>
                <div className="magazine-nav-inner-button flex-shrink-1 flex-grow-1 text-center cursor-pointer" onClick={goToNextPage}>
                    <FontAwesomeIcon icon={ faChevronRight } size='lg' />
                </div>
            </div> : null}
        </Fragment>
    );
};

export default connectMagazineNavigation(MagazineNavButtons);
