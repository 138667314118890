import { MagazineSectionType } from 'common/enums/magazines';

// Published Magazine Page
export const magazineRatio = 4 / 3; // Width / Height

export const MagazineTypesWithHtmlDescription = [
    MagazineSectionType.Default,
    MagazineSectionType.DefaultButCarousel,
    MagazineSectionType.DefaultButMatterPort,
    MagazineSectionType.DefaultButVideo,
    MagazineSectionType.UpsideDown,
    MagazineSectionType.UpsideDownButCarousel,
    MagazineSectionType.UpsideDownButMatterPort,
    MagazineSectionType.UpsideDownButVideo,
    MagazineSectionType.OnlyText
];

export const MagazineSingleColorPalattes = [
    'rgba(0, 0, 0, 0.4)', 'rgba(0, 0, 0, 0.4)', 'rgba(0, 0, 0, 0.4)', 'rgba(0, 0, 0, 0.4)', 
    'rgba(0, 0, 0, 0.4)', 'rgba(0, 0, 0, 0.4)', 'rgba(0, 0, 0, 0.4)', 'rgba(0, 0, 0, 0.4)'
];

export const MagazineVividColorPalattes = [
    '#DE3163',
    '#FFBF00',
    '#CCCCFF',
    '#40E0D0',
    '#FF7F50',
    '#DFFF00',
    '#6495ED',
    '#9FE2BF',
];
