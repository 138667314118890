import React, { Fragment, useEffect, useState, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Card, Carousel, Figure } from 'react-bootstrap';
import { GoogleMap, Marker, InfoWindow, Circle, useJsApiLoader } from '@react-google-maps/api';
import Vimeo from '@u-wave/react-vimeo';
import YouTube from '@u-wave/react-youtube';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faVolumeMute,
    faVolumeOff,
    faPlay,
    faPause
} from '@fortawesome/free-solid-svg-icons'

import DatatTreeAPI from 'common/api/datatree';
import { MagazineSectionType } from 'common/enums/magazines';

import './magazine-section.scss';
import { LoadingSpinner } from 'components/common/spinner';

// Each Items

const TextItem = React.memo(({ content, fullHeight = false }) => {
    return (
        <div className={classNames('d-flex', 'w-100', 'p-30', 'flex-column', {
            'h-100': fullHeight,
            'max-h-100': fullHeight,
            'min-h-100': fullHeight,
            'h-50': !fullHeight,
            'max-h-50': !fullHeight,
            'min-h-50': !fullHeight
        })} 
        style={{background: 'rgba(119, 138, 239, 0.4)'}}>
            <div className='overflow-y-hidden' style={{ overflowWrap: 'break-word'}} dangerouslySetInnerHTML={{ __html: content }}></div>
        </div>);
});

const ImageItem = React.memo(({ url, fullHeight = false }) => {
    return (
    <div className='flex-auto overflow-hidden position-relative'>
        <div className='absolute-fit-parent' style={{ backgroundImage: `url(${url})`, backgroundSize: 'cover', backgroundPosition: 'center' }}></div>
    </div>
    );
});


const IFrameItem = React.memo(({ url, fullHeight = false }) => {
    return (<iframe className='h-100 border-0' title={url} src={url}></iframe>);
});

const CarouselItem = React.memo(({ images }) => {
    return (<div className='flex-auto d-flex overflow-hidden position-relative'>
        <Carousel touch className='absolute-fit-parent' indicators={false} controls interval={1500}>
            {images.map((imageURL, ii) => <Carousel.Item className='mw-100 h-100 mh-100 position-relative' key={ii}>
                <div className='absolute-fit-parent' style={{ backgroundImage: `url(${imageURL})`, backgroundSize: 'cover', backgroundPosition: 'center' }}></div>
            </Carousel.Item>
            )}
        </Carousel>
    </div>);
});

const VideoItem = React.memo(({ url }) => {
    const [muted, setMuted] = useState(true);
    const [playing, setPlaying] = useState(true);
    const videoRef = useRef(null);

    const togglePlay = () => {
        if (videoRef) {
            if (videoRef.current.paused) {
                videoRef.current.play();
                setPlaying(true);
            } else {
                videoRef.current.pause();
                setPlaying(false);
            }
        }
    };
    
    return (<div className='flex-auto d-flex position-relative overflow-hidden'>
        <video ref={videoRef} autoPlay muted={muted} loop preload='' playsInline poster='' className='w-100' style={{ objectFit: 'cover' }}>
            <source src={url} type='video/mp4' />
        </video>
        <div className='mute-icon-wrapper cursor-pointer position-absolute' onClick={togglePlay} style={{ bottom: 10, left: 10 }} >
            <FontAwesomeIcon icon={playing ? faPause : faPlay} />
        </div>
        <div className='mute-icon-wrapper cursor-pointer position-absolute' onClick={() => setMuted(!muted)} style={{ bottom: 10, right: 10 }} >
            <FontAwesomeIcon icon={!muted ? faVolumeMute : faVolumeOff} size='lg' />
        </div>
    </div>);
});

const MatterPortItem = React.memo(({ url }) => {
    let autoPlayURL = url;
    if (!String(url).includes('play=1')) {
        autoPlayURL = autoPlayURL + '&play=1';
    }
    return (<div className='flex-auto overflow-hidden position-relative'>
        <iframe title='Matterport' className='absolute-fit-parent w-100 h-100 border-0' src={autoPlayURL} />
    </div>);
});

const VimeoItem = React.memo(({ id }) => {
    return (<div className='flex-auto d-flex justify-content-center align-items-center overflow-hidden'>
        {!!id ? <Vimeo className='w-100' responsive video={id} /> : null}
    </div>);
});

const YoutubeItem = React.memo(({ id }) => {
    return (<div className='flex-auto d-flex justify-content-center align-items-center overflow-hidden'>
        {!!id ? <YouTube className='w-100' responsive video={id} /> : null}
    </div>);
});

const IntroItem = React.memo(({ logo, company, agent, sm, fullHeight = false }) => {
    return (<div className={classNames('d-flex', 'w-100', 'p-20', 'bg-white', 'flex-column', {
        'h-100': fullHeight,
        'h-50': !fullHeight
    })}>
        <Figure.Image className='max-w-50 max-h-40 mx-auto' src={logo} />
        {!company ? null : <p className={classNames('text-black', 'text-center', 'mb-3', { 'h1': !sm, 'responsive-title': !sm, 'h4': sm })}>{company}</p>}
        <div className={classNames('text-black', 'text-center', 'white-space-prewrap', 'overflow-hidden', { 'responsive-description': !sm })} >{agent}</div>
    </div>);
});

const SalesComparable = React.memo(({ title, address, sm }) => {
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: 'AIzaSyAka8qQefeZWTHSlCdfR8h1nShmSyNw4d0'
    });

    const [initialized, setInitialized] = useState(false);
    const [comparableData, setComparableData] = useState(null);
    const [subject, setSubject] = useState(null);
    const [filters, setFilters] = useState(null);
    const [comparables, setComparables] = useState([]);
    const [center, setCenter] = useState({ lat: 0, lng: 0 });
    const [map, setMap] = React.useState(null);
    const [propertyMarker, setPropertyMarker] = useState(null);

    useEffect(() => {
        getSalesComparison();
    }, []);

    const getSalesComparison = () => {
        if (!address || !address.address_components) {
            setInitialized(true);
            return;
        }
        const StreetNumber = address.address_components.find(comp => comp.types.includes('street_number')).long_name;
        const StreetName = address.address_components.find(comp => comp.types.includes('route')).long_name.split(' ')[0];
        const ZipCode = address.address_components.find(comp => comp.types.includes('postal_code')).long_name;
        const addressDetail = { StreetNumber, StreetName, ZipCode };
        if (sm) {
            setCenter(address.geometry.location);
            setInitialized(true);
            return;
        }
        DatatTreeAPI.getSalesComparablesByAddress(addressDetail).then(res => {
            console.log(res);
            setComparableData(res);

            // Subject
            setSubject(res.Data.SubjectProperty);
            const { LocationInformation } = res.Data.SubjectProperty;
            setCenter({
                lat: LocationInformation.Latitude,
                lng: LocationInformation.Longitude
            });

            // Filters
            setFilters(res.Data.Filters);

            // Comparables
            setComparables(res.Data.ComparableProperties.map(com => ({ ...com, visibleInfoWindow: false })));

            // Init Flag
            setInitialized(true);
        }, e => {
            setComparableData(null);
            setInitialized(true);
        });
    };

    const onLoad = React.useCallback(map => {
        setPropertyMarker({
            // url: "https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png",
            url: 'https://maps.google.com/mapfiles/ms/icons/green.png',
            // size: new window.google.maps.Size(20, 32),
            // origin: new window.google.maps.Point(0, 0),
            anchor: new window.google.maps.Point(0, 0),
        });
        setMap(map);
    }, []);

    const onUnmount = React.useCallback(map => setMap(null), []);

    const setVisibleInfoWindow = (index, visibility = true) => {
        setComparables(comparables.map((com, ci) => ci !== index ? com : { ...com, visibleInfoWindow: visibility }));
    };

    const filterDate = strDate => {
        if (!strDate) {
            return 'Unknown'
        };
        const thisDate = new Date(strDate);
        return `${thisDate.getMonth() + 1}-${thisDate.getDate()}-${thisDate.getFullYear()}`;
    };

    return (<div className='flex-auto d-flex flex-column overflow-hidden position-relative bg-white'>
        {/* {!title ? null : <p className={classNames('text-black', 'my-3', 'text-center', {
            'h1': !sm, 'responsive-title': !sm, 'h4': sm
        })} style={{ overflowWrap: 'break-word' }}>{title}</p>} */}
        <div className='flex-auto bg-white position-relative'>
            <div className='absolute-fit-parent d-flex'>
                {!initialized ? <LoadingSpinner size='xl' className='mx-auto my-auto' /> : null}
                {initialized && isLoaded ? <GoogleMap
                    mapContainerClassName={classNames('d-md-block flex-auto', {
                        'd-none': !sm
                    })}
                    mapContainerStyle={{ width: 900 }} center={center} zoom={15}
                    onLoad={onLoad} onUnmount={onUnmount}>
                    <Marker position={{ lat: center.lat, lng: center.lng }} title={!subject ? address.formatted_address : subject.LocationInformation.APN} />
                    {!filters ? null : <Circle center={center} radius={filters.DistanceFromSubject.DistanceFromSubject} />}
                    {comparables.map((com, ci) => <Marker key={ci}
                        title={com.LocationInformation.APN}
                        position={{ lat: com.LocationInformation.Latitude, lng: com.LocationInformation.Longitude }}
                        icon={propertyMarker}
                        onClick={() => setVisibleInfoWindow(ci)}
                    >
                        {!com.visibleInfoWindow ? null : <InfoWindow onCloseClick={() => setVisibleInfoWindow(ci, false)}>
                            <div>
                                <p className='font-weight-bold mb-1'>APN: {com.LocationInformation.APN}</p>
                                <p>{`${com.SitusAddress.StreetAddress}, ${com.SitusAddress.City}, ${com.SitusAddress.County}, ${com.SitusAddress.State}, ${com.SitusAddress.Zip9}`}</p>
                                <p className='mb-1'>Sale / Rec Date: {filterDate(com.LastMarketSaleInformation.SaleDate)} / {filterDate(com.LastMarketSaleInformation.RecordingDate)}</p>
                                <p className='mb-1'>Sale Price: ${com.LastMarketSaleInformation.SalePrice}</p>
                                <p className='mb-1'>Distance: {String(Math.ceil(parseFloat(com.DistanceFromSubject) * 1000)) + 'm'}</p>
                            </div>
                        </InfoWindow>}
                    </Marker>
                    )}
                </GoogleMap> : null}
                {initialized && subject && !sm? <div className='p-3 pretty-scroll flex-auto' style={{ minWidth: 300 }}>
                    <h2 className='font-weight-bold text-primary mb-1'>Sales Comparable </h2>
                    <p><small>{`${subject.SitusAddress.StreetAddress}, ${subject.SitusAddress.City}, ${subject.SitusAddress.County}, ${subject.SitusAddress.State}, ${subject.SitusAddress.Zip9}`}</small></p>
                    {comparables.map((com, ci) => <Card key={ci} className='mb-2 shadow-sm'>
                        <Card.Body>
                            <Card.Title>
                                <p className='h6 font-weight-bold'>Comp #{ci + 1} - {String(Math.ceil(parseFloat(com.DistanceFromSubject) * 1000)) + 'm'} away from the subject</p>
                                <p><small>{`${com.SitusAddress.StreetAddress}, ${com.SitusAddress.City}, ${com.SitusAddress.County}, ${com.SitusAddress.State}, ${com.SitusAddress.Zip9}`}</small></p>
                            </Card.Title>
                            <Card.Subtitle className="mb-2 text-muted">
                                {/* <small>{filterDate(com.LastMarketSaleInformation.RecordingDate)}</small> */}
                            </Card.Subtitle>
                            <Card.Text className='mb-1'>
                                <small>Sale Price: ${com.LastMarketSaleInformation.SalePrice}</small><br />
                                <small>Sale / Rec Date: {filterDate(com.LastMarketSaleInformation.SaleDate)} / {filterDate(com.LastMarketSaleInformation.RecordingDate)}</small><br />
                                <small>Year Built: {com.PropertyCharacteristics.YearBuilt},&nbsp;&nbsp;Living Area: {com.PropertyCharacteristics.LivingArea}m²</small><br />
                                <small>Bed Rooms: {com.PropertyCharacteristics.Bedrooms},&nbsp;&nbsp;Baths (F / H): {`${com.PropertyCharacteristics.FullBath} / ${com.PropertyCharacteristics.HalfBath}`},&nbsp;&nbsp;Total Rooms: {com.PropertyCharacteristics.TotalRooms}</small><br />
                                <small>Air Conditioning: {!!com.PropertyCharacteristics.AirConditioning ? 'Yes' : 'No'},&nbsp;&nbsp;Pool: {!!com.PropertyCharacteristics.Pool ? 'Yes' : 'No'}</small>
                            </Card.Text>
                            <Card.Link className='float-right cursor-pointer' onClick={() => setVisibleInfoWindow(ci)}><small>Point out</small></Card.Link>
                        </Card.Body>
                    </Card>)}
                </div> : null}

            </div>
        </div>
    </div>)
});

function MagazineSection({ type, text1, text2, asset, assets, preview }) {
    const [isUpsideDown, setIsUpsideDown] = useState(type.includes('UPSIDE-DOWN') ? true : false);

    const DefaultItem = () => (
        <Fragment>
            <ImageItem url={asset} />
            <TextItem content={text2} sm={preview} />
        </Fragment>
    );
    const DefaultButVideoItem = () => (
        <Fragment>
            <VideoItem url={asset} />
            <TextItem content={text2} sm={preview} />
        </Fragment>
    );

    const DefaultButCarouselItem = () => (
        <Fragment>
            <CarouselItem images={assets} />
            <TextItem content={text2} sm={preview} />
        </Fragment>
    );

    const DefaultButMatterPortItem = () => (
        <Fragment>
            <MatterPortItem url={asset} />
            <TextItem content={text2} sm={preview} />
        </Fragment>
    );

    const IntroductionItem = () => (
        <Fragment>
            <ImageItem url={assets.length > 0 ? assets[0] : ''} />
            <IntroItem logo={asset} company={text1} agent={text2} sm={preview} />
        </Fragment>
    );

    useEffect(() => {
        setIsUpsideDown(type.includes('UPSIDE-DOWN') ? true : false);
    }, [type]);

    const renderItem = () => {
        switch (type) {
            case MagazineSectionType.Empty:
                return <div className='w-100 h-100' />;
            case MagazineSectionType.Default:
            case MagazineSectionType.UpsideDown:
                return <DefaultItem />;
            case MagazineSectionType.DefaultButVideo:
            case MagazineSectionType.UpsideDownButVideo:
                return <DefaultButVideoItem />;
            case MagazineSectionType.DefaultButCarousel:
            case MagazineSectionType.UpsideDownButCarousel:
                return <DefaultButCarouselItem />;
            case MagazineSectionType.DefaultButMatterPort:
            case MagazineSectionType.UpsideDownButMatterPort:
                return <DefaultButMatterPortItem />;
            case MagazineSectionType.OnlyCarousel:
                return <CarouselItem images={assets} fullHeight />;
            case MagazineSectionType.OnlyMatterport:
                return <MatterPortItem url={asset} fullHeight />
            case MagazineSectionType.OnlyVideo:
                return <VideoItem url={asset} fullHeight />;
            case MagazineSectionType.OnlyImage:
                return <ImageItem url={asset} fullHeight />;
            case MagazineSectionType.OnlyText:
                return <TextItem content={text2} sm={preview} fullHeight />;
            case MagazineSectionType.OnlyVimeo:
                return <VimeoItem id={asset} fullHeight />;
            case MagazineSectionType.OnlyYoutube:
                return <YoutubeItem id={asset} fullHeight />;
            case MagazineSectionType.OnlySalesComparable:
                return <SalesComparable title={text1} address={JSON.parse(asset)} sm={preview} />;
            case MagazineSectionType.OnlyIframe:
                return <IFrameItem url={asset}/>;
            case MagazineSectionType.Introduction:
            case MagazineSectionType.GoodBye:
                return <IntroductionItem />;
            default:
                return <DefaultItem />;
        }
    };

    return (
        <div className={classNames('flex-1', 'flex-grow-1', 'flex-shrink-1', 'd-flex', 'justify-content-center', 'h-100', 'card-box', {
            'flex-column': !isUpsideDown,
            'flex-column-reverse': isUpsideDown
        })}>
            {renderItem()}
        </div>
    );
};

MagazineSection.propTypes = {
    type: PropTypes.oneOf(Object.values(MagazineSectionType)),
    text1: PropTypes.string,
    text2: PropTypes.string,
    asset: PropTypes.string,
    assets: PropTypes.arrayOf(PropTypes.string),
    preview: PropTypes.bool
};

MagazineSection.defaultProps = {
    type: MagazineSectionType.Default,
    text1: '',
    text2: '',
    asset: '',
    assets: [],
    preview: false
};

export default MagazineSection;
