// UI Theme
export const themes = [ 'light', 'dark' ];
export const defaultTheme = 'light';

// Const Names
export const ConstantNames = {
    AccessToken: 'access-token',
    UITheme: 'ui-theme'
}

// API Integration
const DevServerURL = 'http://localhost';
const ProdServerURL = 'https://api.homly.ai';
const ServerURL = process.env.NODE_ENV === 'production' ? ProdServerURL : DevServerURL;
export const APIServerURL = `${ServerURL}/api`;
export const ResourceURL = `${ServerURL}`;

