import React from 'react';
import { Breadcrumb, Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import {
    Switch,
    Route,
    Redirect,
    withRouter,
    Link
} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons'

import AdminDashboard from 'pages/admin/dashboard';
import AdminMagazines from 'pages/admin/magazines';
import AdminUsers from 'pages/admin/users';
import AdminComparison from 'pages/admin/comparison';
import AdminSettings from 'pages/admin/settings';

import { connectAuth } from 'common/redux/connects';
// import { CustomSwitch } from 'components/common/switch';
// import { isDarkTheme, toggleDarkTheme } from 'common/utils/theme.utils';

function AdminLayout({ history, match, logoutUserAction }) {
    return (<Container fluid className='m-0 p-0'>
        <Navbar bg='dark' variant='dark' expand='md'>
            <Navbar.Brand><Link to='/' className='text-decoration-none text-white'>Homly True Predictive Inteligence- Listing Appointment Assurance</Link></Navbar.Brand>
            <Navbar.Toggle />
            <Navbar.Collapse className='justify-content-end'>
                <Nav className='ml-auto mr-3'>
                    {/* <CustomSwitch className='my-auto' value={isDarkTheme()} onChange={e => toggleDarkTheme(e.target.checked)} />
                    <Nav.Link disabled><span className='text-white'>Dark Theme</span></Nav.Link> */}
                    <NavDropdown alignRight title={<FontAwesomeIcon className='mx-1 my-auto' icon={faUser} size='lg' />}>
                        <NavDropdown.Item disabled>Profile</NavDropdown.Item>
                        <NavDropdown.Item disabled>Notifications</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item onClick={logoutUserAction}>Logout</NavDropdown.Item>
                    </NavDropdown>
                </Nav>
            </Navbar.Collapse>
        </Navbar>

        <div id='layout-drawer' className='row mw-100 flex-column flex-md-row m-0'>
            <aside className="col-12 col-md-2 p-0 bg-dark flex-shrink-1">
                <Nav defaultActiveKey={`${match.url}/dashboard`} expand='md' variant='dark' bg='dark'
                    className="flex-md-column flex-row align-items-start pt-5 pb-2 overflow-x-hidden">
                    <Nav.Item>
                        <Link to={`${match.url}/dashboard`} className='text-decoration-none text-truncate'>Dashboard</Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Link to={`${match.url}/magazines`} className='text-decoration-none text-truncate'>Magazines</Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Link to={`${match.url}/users`} className='text-decoration-none text-truncate'>Users</Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Link to={`${match.url}/comparison`} className='text-decoration-none text-truncate'>Comparison</Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Link to={`${match.url}/settings`} className='text-decoration-none text-truncate'>Settings</Link>
                    </Nav.Item>
                </Nav>
            </aside>
            <main className='col bg-faded flex-grow-1'>
                <Breadcrumb>
                    <Breadcrumb.Item href="#">Home</Breadcrumb.Item>
                    <Breadcrumb.Item href="#">Library</Breadcrumb.Item>
                    <Breadcrumb.Item active>Data</Breadcrumb.Item>
                </Breadcrumb>
                <Switch>
                    <Route exact path={`${match.url}/dashboard`} component={AdminDashboard} />
                    <Route exact path={`${match.url}/magazines`} component={AdminMagazines} />
                    <Route exact path={`${match.url}/users`} component={AdminUsers} />
                    <Route exact path={`${match.url}/comparison`} component={AdminComparison} />
                    <Route exact path={`${match.url}/settings`} component={AdminSettings} />
                    <Route path='/' >
                        <Redirect to={`${match.url}/dashboard`} />
                    </Route>
                </Switch>
            </main>
        </div>
    </Container>);
};

export default withRouter(connectAuth(AdminLayout));