import {
    MAGAZINE_PREV_PAGE,
    MAGAZINE_NEXT_PAGE,
    MAGAZINE_LAST_PAGE,
    MAGAZINE_FIRST_PAGE,
    MAGAZINE_SPECIFIC_PAGE,
    MAGAZINE_SET_PAGES
} from '../actions/types';

const initState = {
    index: 0,
    pagesCount: 0,
    sectionsCount: 0,
    pageSections: []
};

const magazineReducer = (state = initState, action) => {
    switch (action.type) {
        case MAGAZINE_PREV_PAGE:
            return { ...state, index: Math.max(state.index - 1, 0) };
        case MAGAZINE_NEXT_PAGE:
            return { ...state, index: Math.min(state.index + 1, state.pagesCount - 1) };
        case MAGAZINE_LAST_PAGE:
            return { ...state, index: state.pagesCount - 1 };
        case MAGAZINE_FIRST_PAGE:
            return { ...state, index: 0 };
        case MAGAZINE_SPECIFIC_PAGE:
            const { pageIndex } = action.payload;
            return { ...state, index: pageIndex };
        case MAGAZINE_SET_PAGES:
            return {
                ...state,
                pagesCount: action.payload.pagesCount,
                sectionsCount: action.payload.sectionsCount,
                pageSections: action.payload.pageSections
            }
        default:
            return state;
    }
  };

export default magazineReducer;
