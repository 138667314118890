import React, { useEffect, useState } from 'react';
import { Alert, Button, Card, Col, Container, Form, FormControl, InputGroup, Modal, Row, Tab, Table, Tabs } from 'react-bootstrap';

import AlternativesAPI from 'common/api/alternatives';
import { LoadingSpinner } from 'components/common/spinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { ConfirmPopover } from 'components/common/popover';
import { connectToasts } from 'common/redux/connects';
import { ToastStatus } from 'common/enums/toast';
import { validateDomain } from 'common/utils/common.utils';
import { defaultLoadingOption } from 'common/constants/common';

const initAlternative = { name: '', url: '', description: '' };
const initValidationErrors = { name: '', url: '', description: '', validated: false };

function AdminComparison({ addToastAction }) {
    const [alternatives, setAlternatives] = useState([]);
    const [curAlternative, setCurAlternative] = useState(initAlternative);
    const [validationErrors, setValidationErrors] = useState(initValidationErrors);

    const [newCategory, setNewCategory] = useState('');
    const [categories, setCategories] = useState([]);
    const [comparisonTable, setComparisonTable] = useState([]);

    const [loadingOption, setLoadingOption] = useState(defaultLoadingOption);
    const [visibleAlternativeModal, setVisibleAlternativeModal] = useState(false);

    // Confirm Popver
    const [visibleAlternativeConfirm, setVisibleAlternativeConfirm] = useState(false);
    const [confirmAlternativeRef, setConfirmAlternativeRef] = useState(null);
    const [visibleCategoryConfirm, setVisibleCategoryConfirm] = useState(false);
    const [confirmCategoryRef, setConfirmCategoryRef] = useState(null);
    const [deletingId, setDeletingId] = useState('');

    useEffect(() => {
        setLoadingOption({ ...loadingOption, isLoading: true });
        Promise.all([
            AlternativesAPI.getAllternatives(),
            AlternativesAPI.getAllCategories(),
        ]).then(res => {
            setLoadingOption({ ...loadingOption, isLoading: false, isInitialized: true });
            setAlternatives(res[0]);
            setCategories(res[1]);
        }, e => {
            setLoadingOption({ ...loadingOption, isLoading: false, isInitialized: true });
        });
    }, []);

    useEffect(() => {
        const table = [];
        for (const category of categories) {
            const row = [];
            for (const alternative of alternatives) {
                const curAlternatives = category.alternatives || [];
                const existing = curAlternatives.find(a => a.id === alternative.id);
                row.push(!!existing);
            }
            table.push(row);
        }
        setComparisonTable(table);
        setLoadingOption({ ...loadingOption, isLoading: false, action: '' });
    }, [alternatives, categories]);

    // Alternatives

    const onUpdateCurAlternative = (value, key) => {
        setCurAlternative({ ...curAlternative, [key]: value });
    }

    const onClickCreateAlternative = () => {
        setCurAlternative(initAlternative);
        setValidationErrors(initValidationErrors);
        setVisibleAlternativeModal(true);
    };

    const onSubmitAlternative = () => {
        const { newValidationErrors, invalid } = validateAlternativeForm();
        setValidationErrors({ ...newValidationErrors, validated: true });
        if (invalid) { return; }

        if (!curAlternative.id) {
            // Register a new Alternative
            setLoadingOption({ ...loadingOption, isLoading: true, action: 'Create-Alternative' });
            AlternativesAPI.registerAlternative(curAlternative).then(res => {
                setAlternatives(alternatives.concat([{ ...curAlternative, id: res.id }]));
                addToastAction('Success', 'You have successfully registered a new alternative platform.', ToastStatus.Success);
                setLoadingOption({ ...loadingOption, isLoading: false, action: '' });
                setVisibleAlternativeModal(false);
            }, e => {
                setLoadingOption({ ...loadingOption, isLoading: false, action: '' });
                addToastAction(`[${e.statusCode}] ${e.error}`, e.message, ToastStatus.Danger);
            });
            
        } else {
            // Update Alternative
            setLoadingOption({ ...loadingOption, isLoading: true, action: 'Update-Alternative' });
            AlternativesAPI.updateAlternative(curAlternative).then(res => {
                setAlternatives(alternatives.map(a => a.id === curAlternative.id ? curAlternative : a));
                addToastAction('Success', 'You have successfully udpated a new alternative platform.', ToastStatus.Success);
                setLoadingOption({ ...loadingOption, isLoading: false, action: '' });
                setVisibleAlternativeModal(false);
            }, e => {
                setLoadingOption({ ...loadingOption, isLoading: false, action: '' });
                addToastAction(`[${e.statusCode}] ${e.error}`, e.message, ToastStatus.Danger);
            });
        }
    };

    const onClickRemoveAlternative = (event, alternativeId) => {
        setConfirmAlternativeRef(event.target);
        setDeletingId(alternativeId);
        setVisibleAlternativeConfirm(true);
    };

    const onRemoveSelectedAlternative = () => {
        // Delete and update variables
        setLoadingOption({ ...loadingOption, isLoading: true, action: 'Delete-Alternative' });
        AlternativesAPI.deleteAlternative(deletingId).then(res => {
            setAlternatives(alternatives.filter(alternative => alternative.id !== deletingId));
            setLoadingOption({ ...loadingOption, isLoading: false, action: '' });
        }, e => {
            setLoadingOption({ ...loadingOption, isLoading: false, action: '' });
            addToastAction(`[${e.statusCode}] ${e.error}`, e.message, ToastStatus.Danger);
        });
        setVisibleAlternativeConfirm(false);
    };

    const onClickUpdateAlternative = (alternative) => {
        setCurAlternative(alternative);
        setValidationErrors(initValidationErrors);
        setVisibleAlternativeModal(true);
    };

    const validateAlternativeForm = () => {
        // Validate Registration Form
        const newValidationErrors = { name: '', url: '', description: '' };
        let valid = true;
        if (!curAlternative.url) {
            newValidationErrors.url = 'Please enter an web site url';
            valid = false;
        } else if (!validateDomain(curAlternative.url)) {
            newValidationErrors.url = 'Please enter valid url';
            valid = false;
        }
        if (!curAlternative.name) {
            newValidationErrors.name = 'Please enter alternative platform name';
            valid = false;
        }
        return { newValidationErrors, invalid: !valid };
    };

    // Comparison
    const onClickCreateCategory = () => {
        if (!newCategory) {
            addToastAction('Warning', 'Category name can not be empty.', ToastStatus.Warning);
            return;
        }
        setLoadingOption({ ...loadingOption, isLoading: true, action: 'Create-Comparison' });
        AlternativesAPI.registerCategory({ name: newCategory }).then(res => {
            setCategories(categories.concat([{ name: newCategory, id: res.id }]));
            setNewCategory('');
            setLoadingOption({ ...loadingOption, isLoading: false, action: '' });
        }, e => {
            setLoadingOption({ ...loadingOption, isLoading: false, action: '' });
            addToastAction(`[${e.statusCode}] ${e.error}`, e.message, ToastStatus.Danger);
        });
    };

    const onRemoveCategory = (event, categoryId) => {
        setConfirmCategoryRef(event.target);
        setDeletingId(categoryId);
        setVisibleCategoryConfirm(true);
    };

    const onChangeComparison = (value, row, col) => {
        const newTable = comparisonTable.map((r, ri) => ri === row ? r.map((c, ci) => ci === col ? value : c) : r);
        setComparisonTable(newTable);
        udpateCategoriesFromComparisonTable(newTable);
    };

    const udpateCategoriesFromComparisonTable = (table = null) => {
        const newTable = table ? table : comparisonTable;
        const newCategories = [];
        for (let i = 0; i < newTable.length; i++) {
            const r = newTable[i];
            const rr = { ...categories[i], alternatives: [] };
            for (let j = 0; j < r.length; j++) {
                if (r[j]) {
                    rr.alternatives.push(alternatives[j]);
                }
            }
            newCategories.push(rr);
        }
        setCategories(newCategories);
    };

    const onSubmitComparison = () => {
        setLoadingOption({ ...loadingOption, isLoading: true, action: 'Update-Comparison' });
        AlternativesAPI.updateComparison({ comparison: categories }).then(res => {
            setLoadingOption({ ...loadingOption, isLoading: false, action: '' });
        }, e => {
            setLoadingOption({ ...loadingOption, isLoading: false, action: '' });
            addToastAction(`[${e.statusCode}] ${e.error}`, e.message, ToastStatus.Danger);
        });
    };

    const onRemoveSelectedCategory = () => {
        setVisibleCategoryConfirm(false);
        setLoadingOption({ ...loadingOption, isLoading: true, action: 'Remove-Comparison' });
        AlternativesAPI.deleteCategory(deletingId).then(res => {
            setCategories(categories.filter(category => category.id !== deletingId));
        }, e => {
            setLoadingOption({ ...loadingOption, isLoading: false, action: '' });
            addToastAction(`[${e.statusCode}] ${e.error}`, e.message, ToastStatus.Danger);
        });
    };

    //TODO: Found error in Submit comparison but not sure when it occurs

    return (<Container fluid>
        <h1>Admin Comparison</h1>
        <Tabs defaultActiveKey='ADMIN-ALTERNATIVES'>
            <Tab eventKey='ADMIN-ALTERNATIVES' title='Alternatives'>
                <Row>
                    <Col sm={12}>
                        <h2 className='my-4'>Alternatives</h2>
                    </Col>
                    <Col sm={12} className='mb-3'>
                        <Button disabled={loadingOption.isLoading} variant="success" className='float-right' onClick={onClickCreateAlternative}>
                            New Alternative
                        </Button>
                    </Col>
                    {!loadingOption.isInitialized ? <LoadingSpinner className='mx-auto' size='xl' /> :
                        (alternatives.length < 1 ? <Alert variant='warning'>No Alternatives found</Alert> :
                            alternatives.map((alternative, ai) => <Col key={ai} sm={6} md={6} lg={4} className='mb-2'>
                                <Card>
                                    <Card.Body>
                                        <Card.Title className='mb-4'>
                                            <FontAwesomeIcon className='float-right text-danger cursor-pointer' icon={faTimes} onClick={(e) => onClickRemoveAlternative(e, alternative.id)} />
                                        </Card.Title>
                                        <Card.Title><b>{alternative.name}</b></Card.Title>
                                        <Card.Link href={alternative.url} className='text-muted' target="_blank">{alternative.url}</Card.Link>
                                        <Card.Text className='mt-2'>
                                            {alternative.description}
                                        </Card.Text>
                                        <Card.Link className='cursor-pointer float-right' onClick={() => onClickUpdateAlternative(alternative)}>Update</Card.Link>
                                    </Card.Body>
                                </Card>
                            </Col>))}
                </Row>
                <Modal centered show={visibleAlternativeModal} size='lg' className='p-0' onHide={() => setVisibleAlternativeModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>{curAlternative.id ? 'Update ' : 'Create an '}Alternative Platform</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form validated={validationErrors.validated}>
                            <Form.Group controlId='name'>
                                <Form.Label><b>Title</b></Form.Label>
                                <Form.Control placeholder="Type Alternatie Name" value={curAlternative.name} required isInvalid={!!validationErrors.name}
                                    onChange={e => onUpdateCurAlternative(e.target.value, 'name')} />
                                <Form.Text className="text-muted">
                                    You should provide alternative name to identify.
                                </Form.Text>
                                <Form.Control.Feedback type={!!validationErrors.name ? 'invalid' : 'valid'}>{validationErrors.name}</Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group controlId='url'>
                                <Form.Label><b>Website URL</b></Form.Label>
                                <Form.Control placeholder="Type Alternatie Website URL" value={curAlternative.url} required isInvalid={!!validationErrors.url}
                                    onChange={e => onUpdateCurAlternative(e.target.value, 'url')} />
                                <Form.Text className="text-muted">
                                    You could provide alternative url.
                                </Form.Text>
                                <Form.Control.Feedback type={!!validationErrors.url ? 'invalid' : 'valid'}>{validationErrors.url}</Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group controlId='description'>
                                <Form.Label><b>Description</b></Form.Label>
                                <Form.Control as='textarea' placeholder="Type Alternatie Description" value={curAlternative.description} isInvalid={!!validationErrors.description}
                                    onChange={e => onUpdateCurAlternative(e.target.value, 'description')} />
                                <Form.Text className="text-muted">
                                    You could provide alternative description.
                                </Form.Text>
                                <Form.Control.Feedback type={!!validationErrors.description ? 'invalid' : 'valid'}>{validationErrors.description}</Form.Control.Feedback>
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button disabled={loadingOption.isLoading} variant="secondary" onClick={() => setVisibleAlternativeModal(false)}>
                            Close
                        </Button>
                        <Button disabled={loadingOption.isLoading} variant="success" onClick={onSubmitAlternative}>
                            <LoadingSpinner invisible={loadingOption.action !== 'Create-Alternative' && loadingOption.action !== 'Update-Alternative'} />
                            Confirm
                        </Button>
                    </Modal.Footer>
                </Modal>
                <ConfirmPopover visible={visibleAlternativeConfirm} target={confirmAlternativeRef} placement='left' setVisibility={setVisibleAlternativeConfirm}
                    label='Are you sure to remove this alternative platform?' onConfirm={onRemoveSelectedAlternative} />
            </Tab>
            <Tab eventKey='ADMIN-COMPARISON' title='Comparison'>
                <h2 className='my-4'>Comparison</h2>
                <Row>
                    <Col sm={12}>
                        <InputGroup className="mb-3">
                            <FormControl disabled={loadingOption.isLoading} placeholder="Enter the name of comparison category" aria-label="Category" value={newCategory}
                                onKeyPress={event => (event.key === 'Enter') && onClickCreateCategory()} onChange={e => setNewCategory(e.target.value)} />
                            <InputGroup.Append>
                                <Button disabled={loadingOption.isLoading} variant='success' className='float-right' onClick={onClickCreateCategory}>
                                    <LoadingSpinner invisible={loadingOption.action !== 'Create-Comparison'} />
                                    Create
                                </Button>
                            </InputGroup.Append>
                        </InputGroup>
                    </Col>
                    <Col sm={12} className='text-center'>
                        {loadingOption.isLoading ? <LoadingSpinner size='xl' /> : <Table responsive bordered>
                            <thead><tr><th>
                                <span>Categories</span>
                            </th>
                                {alternatives.map((alternative, ai) => <th key={ai}>
                                    <span>{alternative.name}</span>
                                </th>)}
                            </tr></thead>
                            <tbody>{comparisonTable.map((row, ri) => <tr key={ri}>
                                <td>
                                    {categories[ri].name}
                                    <FontAwesomeIcon className='float-right text-danger cursor-pointer' icon={faTimes} onClick={(e) => onRemoveCategory(e, categories[ri].id)} />
                                </td>
                                {row.map((col, ci) => <td key={ci}>
                                    <Form.Check aria-label={`Comparison-[${ri},${ci}]`} checked={col} onChange={(e) => onChangeComparison(e.target.checked, ri, ci)} />
                                </td>)}
                            </tr>)}
                            </tbody>
                        </Table>}
                    </Col>
                    <Col sm={12}>
                        <Button disabled={loadingOption.isLoading} className='float-right px-3' onClick={onSubmitComparison}>
                            <LoadingSpinner invisible={loadingOption.action !== 'Update-Comparison'} />
                            Submit
                        </Button>
                    </Col>
                </Row>
                <ConfirmPopover visible={visibleCategoryConfirm} target={confirmCategoryRef} placement='left' setVisibility={setVisibleCategoryConfirm}
                    label='Are you sure to remove this category?' onConfirm={onRemoveSelectedCategory} />
            </Tab>
        </Tabs>
    </Container>
    );
};

export default connectToasts(AdminComparison);
