import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Form, ListGroup, Overlay, Popover } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload, faTrash, faPlus } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import UploadAPI from 'common/api/upload';
import { findPlaceByGoogle } from 'common/utils/google.util';
import { MagazineSectionType } from 'common/enums/magazines';
import { CustomSelect } from '../common/select';
import { CustomSwitch } from '../common/switch';
import { connectToasts } from 'common/redux/connects';
import { ToastStatus } from 'common/enums/toast';
import { MagazineTypesWithHtmlDescription } from 'common/constants/magazine';

const IntroductionForm = ({ option, onChange, onUploadRequest, disabled }) => {
    const onChangeFormOption = (value, key) => {
        onChange({ ...option, [key]: value })
    };

    const onChangeSpecificAsset = (value, index) => {
        const newOption = JSON.parse(JSON.stringify(option));
        newOption.assets[index] = value;
        onChange(newOption);
    };

    useEffect(() => {
        if (option.assets.length === 0) {
            const newOption = JSON.parse(JSON.stringify(option));
            newOption.assets.push('');
            onChange(newOption);
        }
    }, [option]);

    return (<Fragment>
        <Form.Group>
            <Form.Label><strong>Logo URL</strong></Form.Label>
            {!disabled ? <FontAwesomeIcon className='cursor-pointer float-right text-info' icon={faUpload} size='lg' onClick={() => onUploadRequest('asset')} /> : null}
            <Form.Control disabled={disabled} type='text' placeholder='Logo URL' value={option.asset} onChange={e => onChangeFormOption(e.target.value, 'asset')} />
        </Form.Group>
        <Form.Group>
            <Form.Label><strong>Agent Name</strong></Form.Label>
            <Form.Control disabled={disabled} type='text' as='textarea' rows={3} placeholder='Enter Agent Details'
                value={option.text2} onChange={e => onChangeFormOption(e.target.value, 'text2')} />
        </Form.Group>
        {option.assets.length === 0 ? null : <Form.Group>
            <Form.Label><strong>Asset URL</strong></Form.Label>
            {!disabled ? <FontAwesomeIcon className='cursor-pointer float-right text-info' icon={faUpload} size='lg' onClick={() => onUploadRequest('assets.0')} /> : null}
            <Form.Control disabled={disabled} type='text' placeholder='Enter asset URL' value={option.assets[0]} onChange={e => onChangeSpecificAsset(e.target.value, 0)} />
        </Form.Group>}

    </Fragment>)
};

const DefaultForm = ({ option, onChange, onUploadRequest, disabled }) => {
    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    useEffect(() => {
        const contentBlock = htmlToDraft(option.text2);
        setEditorState(EditorState.createWithContent(
            ContentState.createFromBlockArray(contentBlock.contentBlocks)
        ));
    }, [option]);

    const updateDescription = () => {
        onChangeFormOption(draftToHtml(convertToRaw(editorState.getCurrentContent())), 'text2');
    }

    const onChangeFormOption = (value, key) => {
        onChange({ ...option, [key]: value })
    };

    return (<Fragment>
        <Form.Group>
            <Form.Label><strong>Description</strong></Form.Label>
            <Editor
                editorState={editorState}
                wrapperClassName="description-wrapper"
                editorClassName="description-editor"
                onEditorStateChange={setEditorState}
                onBlur={updateDescription}
            />
        </Form.Group>
        <Form.Group>
            <Form.Label><strong>Asset URL</strong></Form.Label>
            {!disabled ? <FontAwesomeIcon className='cursor-pointer float-right text-info' icon={faUpload}
                size='lg' onClick={() => onUploadRequest('asset')} /> : null}
            <Form.Control disabled={disabled} type='text' placeholder='Enter asset URL' value={option.asset}
                onChange={e => onChangeFormOption(e.target.value, 'asset')} />
        </Form.Group>
    </Fragment>)
};

const MultipleAssetsForm = ({ option, onChange, onUploadRequest, disabled }) => {
    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    useEffect(() => {
        const contentBlock = htmlToDraft(option.text2);
        setEditorState(EditorState.createWithContent(
            ContentState.createFromBlockArray(contentBlock.contentBlocks)
        ));
    }, [option]);

    const updateDescription = () => {
        onChangeFormOption(draftToHtml(convertToRaw(editorState.getCurrentContent())), 'text2');
    }

    const onChangeFormOption = (value, key) => {
        onChange({ ...option, [key]: value })
    };

    const onAddOneAsset = () => {
        const newOption = JSON.parse(JSON.stringify(option));
        newOption.assets.push('');
        onChange(newOption);
    };

    const onChangeInnerAsset = (value, ii) => {
        const newOption = JSON.parse(JSON.stringify(option));
        newOption.assets[ii] = value;
        onChange(newOption);
    };

    const onRemoveInnerAsset = (ii) => {
        const newOption = JSON.parse(JSON.stringify(option));
        newOption.assets.splice(ii, 1);
        onChange(newOption);
    };

    return (<Fragment>
        <Form.Group>
            <Form.Label><strong>Description</strong></Form.Label>
            {/* <Form.Control disabled={disabled} type='text' as='textarea' rows={3} placeholder='Enter description'
                value={option.text2} onChange={e => MultipleAssetsFormOption(e.target.value, 'text2')} /> */}
            <Editor
                editorState={editorState}
                wrapperClassName="description-wrapper"
                editorClassName="description-editor"
                onEditorStateChange={setEditorState}
                onBlur={updateDescription}
            />
        </Form.Group>
        <Form.Group className='mb-1'>
            <Form.Label><strong>Assets</strong></Form.Label>
            {!disabled ? <FontAwesomeIcon className='cursor-pointer float-right text-success' icon={faPlus} size='lg' onClick={onAddOneAsset} /> : null}
        </Form.Group>
        {option.assets.map((eachAsset, ii) =>
            <Form.Group key={ii} className='mb-1'>
                <div className='d-flex'>
                    <Form.Control disabled={disabled} type='text' className='my-1 flex-fill' placeholder='Enter Image URL'
                        value={eachAsset} onChange={e => onChangeInnerAsset(e.target.value, ii)} />
                    {!disabled ? <FontAwesomeIcon className='my-auto cursor-pointer ml-3 float-right text-info' icon={faUpload}
                        size='lg' onClick={() => onUploadRequest('assets.' + ii)} /> : null}
                    {!disabled ? <FontAwesomeIcon className='my-auto cursor-pointer ml-3 float-right text-danger' icon={faTrash}
                        size='lg' onClick={() => onRemoveInnerAsset(ii)} /> : null}
                </div>
            </Form.Group>)}
    </Fragment>)
};

const NoUploadForm = ({ option, onChange, disabled }) => {
    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    useEffect(() => {
        const contentBlock = htmlToDraft(option.text2);
        setEditorState(EditorState.createWithContent(
            ContentState.createFromBlockArray(contentBlock.contentBlocks)
        ));
    }, [option]);

    const updateDescription = () => {
        onChangeFormOption(draftToHtml(convertToRaw(editorState.getCurrentContent())), 'text2');
    }

    const onChangeFormOption = (value, key) => {
        onChange({ ...option, [key]: value })
    };

    const isVideoIDNeeded = () => {
        switch (option.type) {
            case MagazineSectionType.OnlyVimeo:
            case MagazineSectionType.OnlyYoutube:
                return true;
            default:
                return false;
        }
    };

    return (<Fragment>
        <Form.Group>
            <Form.Label><strong>Description</strong></Form.Label>
            {/* <Form.Control disabled={disabled} type='text' as='textarea' rows={3} placeholder='Enter description'
                value={option.text2} onChange={e => onChangeFormOption(e.target.value, 'text2')} /> */}
            <Editor
                editorState={editorState}
                wrapperClassName="description-wrapper"
                editorClassName="description-editor"
                onEditorStateChange={setEditorState}
                onBlur={updateDescription}
            />
        </Form.Group>
        <Form.Group>
            <Form.Label><strong>{!isVideoIDNeeded() ? 'Asset URL' : 'Video ID'}</strong></Form.Label>
            <Form.Control disabled={disabled} type='text' placeholder={'Enter ' + (!isVideoIDNeeded() ? 'Asset URL' : 'Video ID')} value={option.asset}
                onChange={e => onChangeFormOption(e.target.value, 'asset')} />
        </Form.Group>
    </Fragment>)
};

const OnlyAssetForm = ({ option, onChange, onUploadRequest, disabled }) => {
    const onChangeFormOption = (value, key) => {
        onChange({ ...option, [key]: value })
    };

    const isUploadNeeded = React.useMemo(() => {
        switch (option.type) {
            case MagazineSectionType.OnlyImage:
            case MagazineSectionType.OnlyVideo:
                return true;
            case MagazineSectionType.OnlyVimeo:
            case MagazineSectionType.OnlyYoutube:
            case MagazineSectionType.OnlyMatterport:
                return false;
            default:
                return false;
        }
    }, [option.type]);

    const urlTagName = React.useMemo(() => {
        const bUpload = isUploadNeeded;
        if (bUpload || option.type === MagazineSectionType.OnlyIframe) {
            return 'Asset URL';
        } else if (option.type === MagazineSectionType.OnlyMatterport) {
            return 'Matterport URL';
        } else { // Vimeo & Youtube
            return 'Video ID';
        }
    }, [option.type]);

    return (<Fragment>
        <Form.Group>
            <Form.Label><strong>{urlTagName}</strong></Form.Label>
            {!isUploadNeeded || disabled ? null : <FontAwesomeIcon className='cursor-pointer float-right text-info' icon={faUpload} size='lg' onClick={() => onUploadRequest('asset')} />}
            <Form.Control disabled={disabled} type='text' placeholder={'Enter ' + urlTagName}
                value={option.asset} onChange={e => onChangeFormOption(e.target.value, 'asset')} />
        </Form.Group>
    </Fragment>)
};

const OnlyTextForm = ({ option, onChange, disabled }) => {
    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    useEffect(() => {
        const contentBlock = htmlToDraft(option.text2);
        setEditorState(EditorState.createWithContent(
            ContentState.createFromBlockArray(contentBlock.contentBlocks)
        ));
    }, [option]);

    const updateDescription = () => {
        onChangeFormOption(draftToHtml(convertToRaw(editorState.getCurrentContent())), 'text2');
    }

    const onChangeFormOption = (value, key) => {
        onChange({ ...option, [key]: value })
    };

    return (<Fragment>
        <Form.Group>
            <Form.Label><strong>Description</strong></Form.Label>
            {/* <Form.Control disabled={disabled} type='text' as='textarea' rows={3} placeholder='Enter description'
                value={option.text2} onChange={e => onChangeFormOption(e.target.value, 'text2')} /> */}
            <Editor
                editorState={editorState}
                wrapperClassName="description-wrapper"
                editorClassName="description-editor"
                onEditorStateChange={setEditorState}
                onBlur={updateDescription}
            />
        </Form.Group>
    </Fragment>)
};

const OnlyAssetsForm = ({ option, onChange, onUploadRequest, disabled }) => {
    const onAddOneAsset = () => {
        const newOption = JSON.parse(JSON.stringify(option));
        newOption.assets.push('');
        onChange(newOption);
    };

    const onChangeInnerAsset = (value, ii) => {
        const newOption = JSON.parse(JSON.stringify(option));
        newOption.assets[ii] = value;
        onChange(newOption);
    };

    const onRemoveInnerAsset = (ii) => {
        const newOption = JSON.parse(JSON.stringify(option));
        newOption.assets.splice(ii, 1);
        onChange(newOption);
    };

    return (<Fragment>
        <Form.Group className='mb-1'>
            <Form.Label><strong>Assets</strong></Form.Label>
            {!disabled ? <FontAwesomeIcon className='cursor-pointer float-right text-success' icon={faPlus} size='lg' onClick={onAddOneAsset} /> : null}
        </Form.Group>
        {option.assets.map((image, ii) =>
            <Form.Group key={ii} className='mb-1'>
                <div className='d-flex'>
                    <Form.Control disabled={disabled} type='text' className='my-1 flex-fill' placeholder='Enter Image URL'
                        value={image} onChange={e => onChangeInnerAsset(e.target.value, ii)} />
                    {!disabled ? <FontAwesomeIcon className='my-auto cursor-pointer ml-3 float-right text-info' icon={faUpload}
                        size='lg' onClick={() => onUploadRequest('assets.' + ii)} /> : null}
                    {!disabled ? <FontAwesomeIcon className='my-auto cursor-pointer ml-3 float-right text-danger' icon={faTrash}
                        size='lg' onClick={() => onRemoveInnerAsset(ii)} /> : null}
                </div>
            </Form.Group>)}
    </Fragment>)
};

const OnlySalesComparableContent = ({ option, onChange, disabled, addToastAction }) => {
    const [addressList, setAddressList] = useState([]);

    const [visible, setVisible] = useState(false);
    const [target, setTarget] = useState(null);

    useEffect(() => {
        findAddress(option.text2);
    }, []);

    const findAddress = (value) => {
        findPlaceByGoogle(value).then(response => {
            setAddressList(response.results);
            setVisible(false);
        }, error => {
            setAddressList([]);
            setVisible(false);
        });
    };

    const debouncedFindAddress = useCallback(_.debounce(findAddress, 500), []);
    const debouncedShowAddresses = useCallback(_.debounce((event) => {
        if (!addressList.length) {
            setTarget(event.target);
            setVisible(false);
            return;
        }
        setTarget(event.target);
        setVisible(true);
    }, 100));

    const onChangeFormOption = (target, key) => {
        if (visible) {
            setVisible(false);
        }
        onChange({ ...option, [key]: target.value })
        debouncedFindAddress(target.value);
    };

    const validateAddress = (address) => {
        const necessaryComponents = ['street_number', 'route', 'postal_code'];
        for (const necessary of necessaryComponents) {
            if (!address.address_components.find(comp => comp.types.includes(necessary))) {
                return false;
            }
        }
        return true;
    };

    const onSelectAddress = (event, address) => {
        event.preventDefault();
        setVisible(false);
        if (!validateAddress(address)) {
            addToastAction('Warning', 'Sorry, you should select street in order to get Sales Comparable Report.', ToastStatus.Warning);
            return;
        }
        onChange({
            ...option,
            text2: address.formatted_address,
            asset: JSON.stringify(address)
        });
    };

    return (<Fragment>
        <Form.Group>
            <Form.Label><strong>Street Name</strong></Form.Label>
            <Form.Control disabled={disabled} type='text' placeholder='Enter asset URL' value={option.text2}
                onChange={e => onChangeFormOption(e.target, 'text2')} onFocus={e => debouncedShowAddresses(e)} />
        </Form.Group>
        <Form.Row>
            <Overlay show={visible} target={target} placement='bottom' onHide={() => setVisible(false)} containerPadding={20} rootClose>
                <Popover>
                    <Popover.Content>
                        <p className='mt-2 mb-1 ml-2'><b>Recommended Places</b></p>
                        <ListGroup variant='flush' style={{ maxHeight: 200, overflowY: 'auto' }}>
                            {addressList.map((address, ai) => <ListGroup.Item action key={ai} className='px-2 py-1' onClick={(e) => onSelectAddress(e, address)}>
                                <small>{address.formatted_address}</small>
                            </ListGroup.Item>)}
                        </ListGroup>
                    </Popover.Content>
                </Popover>
            </Overlay>
        </Form.Row>
    </Fragment>)
};

const OnlySalesComparable = connectToasts(OnlySalesComparableContent);

const MagazineForm = ({ option, onChange, fullWidthDisabled, disabled, setDisable, addToastAction }) => {
    const [uploadingAssetKey, setUploadingAssetKey] = useState(null);
    const fileInput = useRef(null);

    const onChangeOption = (value, key) => {
        if (key === 'type' && value === MagazineSectionType.OnlySalesComparable) {
            onChange({ ...option, [key]: value, asset: '{}', text2: '' });
        } else if (key === 'type' && MagazineTypesWithHtmlDescription.includes(value)) {
            onChange({ ...option, [key]: value, text2: '<p>Default Description</p>' });
        } else {
            onChange({ ...option, [key]: value });
        }
    };

    const onFileChanged = e => {
        const { files } = e.target;
        if (files.length > 0) {
            const file = e.target.files[0];
            setDisable(true);
            UploadAPI.uploadFile(file).then(fileName => {
                if (uploadingAssetKey.includes('.')) {
                    const keys = uploadingAssetKey.split('.');
                    const newOption = JSON.parse(JSON.stringify(option));
                    newOption[keys[0]][keys[1]] = fileName;
                    onChange(newOption);
                    setDisable(false);
                } else {
                    onChange({ ...option, [uploadingAssetKey]: fileName });
                    setDisable(false);
                }
            }, e => {
                setDisable(false);
                addToastAction(`[${e.statusCode}] ${e.error}`, e.message, ToastStatus.Danger);
            });
        }
    };

    const uploadFile = (uak) => {
        // TODO: filter file types
        /**
         *  <input type="file" accept="image/*" /> <!-- all image types --> 
            <input type="file" accept="audio/*" /> <!-- all audio types --> 
            <input type="file" accept="video/*" /> <!-- all video types --> 
         */
        setUploadingAssetKey(uak);
        fileInput.current.click();
    };

    const renderMagazineFormByType = () => {
        switch (option.type) {
            case MagazineSectionType.Introduction:
            case MagazineSectionType.GoodBye:
                return <IntroductionForm disabled={disabled} option={option} onChange={onChange} onUploadRequest={uploadFile} />;
            case MagazineSectionType.Default:
            case MagazineSectionType.DefaultButVideo:
            case MagazineSectionType.UpsideDown:
            case MagazineSectionType.UpsideDownButVideo:
                return <DefaultForm disabled={disabled} option={option} onChange={onChange} onUploadRequest={uploadFile} />;
            case MagazineSectionType.DefaultButMatterPort:
            case MagazineSectionType.UpsideDownButMatterPort:
                return <NoUploadForm disabled={disabled} option={option} onChange={onChange} />;
            case MagazineSectionType.OnlyImage:
            case MagazineSectionType.OnlyIframe:
            case MagazineSectionType.OnlyMatterport:
            case MagazineSectionType.OnlyVideo:
            case MagazineSectionType.OnlyVimeo:
            case MagazineSectionType.OnlyYoutube:
                return <OnlyAssetForm disabled={disabled} option={option} onChange={onChange} onUploadRequest={uploadFile} />;
            case MagazineSectionType.OnlySalesComparable:
                return <OnlySalesComparable disabled={disabled} option={option} onChange={onChange} />;
            case MagazineSectionType.DefaultButCarousel:
            case MagazineSectionType.UpsideDownButCarousel:
                return <MultipleAssetsForm disabled={disabled} option={option} onChange={onChange} onUploadRequest={uploadFile} />;
            case MagazineSectionType.OnlyText:
                return <OnlyTextForm disabled={disabled} option={option} onChange={onChange} />;
            case MagazineSectionType.OnlyCarousel:
                return <OnlyAssetsForm disabled={disabled} option={option} onChange={onChange} onUploadRequest={uploadFile} />;
            default:
                return <DefaultForm disabled={disabled} option={option} onChange={onChange} onUploadRequest={uploadFile} />;
        }
    };

    const renderTitleFormGroup = () => {
        switch (option.type) {
            case MagazineSectionType.Introduction:
            case MagazineSectionType.GoodBye:
                return <Form.Group>
                    <Form.Label><strong>Company Name</strong></Form.Label>
                    <Form.Control disabled={disabled} type='text' placeholder='Company Name' value={option.text1} onChange={e => onChangeOption(e.target.value, 'text1')} />
                </Form.Group>;
            default:
                return <Form.Group>
                    <Form.Label><strong>Title</strong></Form.Label>
                    <Form.Control disabled={disabled} type='text' placeholder='Enter Title' value={option.text1} onChange={e => onChangeOption(e.target.value, 'text1')}>
                    </Form.Control>
                </Form.Group>;
        }
    };

    return (<Fragment>
        <Form.Group controlId='selectedMagazineType'>
            <CustomSelect disabled={disabled} label='Type' values={Object.values(MagazineSectionType)}
                value={option.type} onChange={e => onChangeOption(e.target.value, 'type')} />
        </Form.Group>
        {renderTitleFormGroup()}
        {renderMagazineFormByType()}
        <Form.File ref={fileInput} label='' className='d-none' onChange={onFileChanged} />
        {fullWidthDisabled ? null : <Form.Row><Col sm={12} className='d-flex mt-2'>
            <p className='mr-3 my-0'><b>Full Width</b></p>
            <CustomSwitch disabled={disabled} className='my-auto text-right' value={option.fullWidth} onChange={e => onChangeOption(e.target.checked, 'fullWidth')} />
        </Col></Form.Row>}
    </Fragment>);
};

MagazineForm.propTypes = {
    option: PropTypes.object,
    onChange: PropTypes.func,
    fullWidthDisabled: PropTypes.bool,
    disabled: PropTypes.bool,
    setDisable: PropTypes.func
};
MagazineForm.defaultProps = {
    option: {},
    onChange: () => { },
    fullWidthDisabled: true,
    disabled: false,
    setDisable: () => { }
};

export default connectToasts(MagazineForm);