import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import {
    Button,
    Card,
    Col,
    Container,
    Row,
    Modal,
    Form,
    Alert,
    ListGroup,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAd, faEdit, faEllipsisH, faEnvelope, faMailBulk, faTimes } from '@fortawesome/free-solid-svg-icons';

import MagazineAPI from 'common/api/magazines';
import { ConfirmPopover } from 'components/common/popover';
import { ToastStatus } from 'common/enums/toast';
import { connectToasts, connectAuthCheck } from 'common/redux/connects';
import { LoadingSpinner } from 'components/common/spinner';
import { validateEmail } from 'common/utils/common.utils';
import { defaultLoadingOption } from 'common/constants/common';
import { UserRole } from 'common/enums/auth';
import './magazines.scss';
import Banner from '../../assets/img/banner.webp';
import House_1 from '../../assets/img/bg.webp';
import { BsPlusCircleFill } from 'react-icons/bs';
import { HiEye } from 'react-icons/hi';
import { BiEdit } from 'react-icons/bi';
import { MdEmail } from 'react-icons/md';
import { AiTwotoneDelete } from 'react-icons/ai';

const initMagazine = { name: '', description: '', publishUrl: '' };

function ClientMagazines({ addToastAction, match, history, userRole }) {
    const [magazines, setMagazines] = useState([]);
    const [visibleUpdateMagazineModal, setVisibleUpdateMagazineModal] = useState(false);
    const [curMagazine, setCurMagazine] = useState(initMagazine);
    const [objSendingEmail, setObjSendingEmail] = useState({ email: '', emails: [], visible: false });
    const [loadingOption, setLoadingOption] = useState(defaultLoadingOption);

    // Confirm Popver
    const [visibleConfirm, setVisibleConfirm] = useState(false);
    const [confirmRef, setConfirmRef] = useState(null);
    const [selectedMgzId, setSelectedMgzId] = useState('');

    useEffect(() => {
        setLoadingOption({ ...loadingOption, isLoading: true });
        MagazineAPI.getMagazines().then(
            (arrMagazines) => {
                setMagazines(arrMagazines);
                setLoadingOption({ ...loadingOption, isLoading: false, isInitialized: true });
            }, (e) => {
                setLoadingOption({ ...loadingOption, isLoading: false, isInitialized: true });
                addToastAction(`[${e.statusCode}] ${e.error}`, e.message, ToastStatus.Danger);
            }
        );
    }, []);

    const updateMgzDetail = (magazine) => {
        setCurMagazine(magazine);
        setVisibleUpdateMagazineModal(true);
    };

    const updateCurMagazine = (key, value) => {
        setCurMagazine({ ...curMagazine, [key]: value });
    };

    const onClickNewMagazineButton = () => {
        setCurMagazine(initMagazine);
        setVisibleUpdateMagazineModal(true);
    };

    const onSubmitMagazine = () => {
        if (!curMagazine.id) {
            // Check if empty fields
            if (!curMagazine.name || !curMagazine.publishUrl) {
                addToastAction(`Warning`, 'You need to enter Name and PublishUrl.', ToastStatus.Warning);
                return;
            }
            setLoadingOption({ ...loadingOption, isLoading: true, action: 'Create' });
            // Register a new magazine
            MagazineAPI.registerMagazine(curMagazine).then(
                (res) => {
                    setMagazines(magazines.concat({ ...curMagazine, id: res.id }));
                    setCurMagazine({ ...curMagazine, id: res.id });
                    setLoadingOption({ ...loadingOption, isLoading: false, action: '' });
                    addToastAction(`Congrationations`, 'You have successfully registered a new magazine.', ToastStatus.Success);
                    setVisibleUpdateMagazineModal(false);
                },
                (e) => {
                    setLoadingOption({ ...loadingOption, isLoading: false, action: '' });
                    addToastAction(`[${e.statusCode}] ${e.error}`, e.message, ToastStatus.Danger);
                }
            );
        } else {
            // Check if updated
            const previousMagazine = magazines.find((magazine) => magazine.id === curMagazine.id);
            if (!previousMagazine) {
                addToastAction(`Danger`, 'Something went wrong. Can you please try again?', ToastStatus.Danger);
                return;
            }
            if (
                previousMagazine.name === curMagazine.name &&
                previousMagazine.description === curMagazine.description &&
                previousMagazine.publishUrl === curMagazine.publishUrl
            ) {
                addToastAction(`Info`, 'Nothing changed.', ToastStatus.Info);
            } else {
                // Update current magazine
                setLoadingOption({ ...loadingOption, isLoading: true, action: 'Update' });
                MagazineAPI.updateMagazine(curMagazine.id, curMagazine).then(
                    (res) => {
                        setMagazines(magazines.map((magazine) => magazine.id === curMagazine.id ? curMagazine : magazine));
                        setCurMagazine({ ...curMagazine, id: res.id });
                        setLoadingOption({ ...loadingOption, isLoading: false, action: '' });
                        addToastAction(`Congrationations`, 'You have successfully updated a magazine.', ToastStatus.Success);
                        setVisibleUpdateMagazineModal(false);
                    },
                    (e) => {
                        setLoadingOption({ ...loadingOption, isLoading: false, action: '' });
                        addToastAction(`[${e.statusCode}] ${e.error}`, e.message, ToastStatus.Danger);
                    }
                );
            }
        }
    };

    const onClickRemoveMagazineButton = (e, mgzId) => {
        console.log(e.target, mgzId)
        setSelectedMgzId(mgzId);
        setConfirmRef(e.target);
        setVisibleConfirm(true);
    };

    const removeMagazine = () => {
        setVisibleConfirm(false);
        setLoadingOption({ ...loadingOption, isLoading: true, action: 'Delete' });
        MagazineAPI.deleteMagazine(selectedMgzId).then(
            (res) => {
                setMagazines(magazines.filter((magazine) => magazine.id !== selectedMgzId));
                setLoadingOption({ ...loadingOption, isLoading: false, action: '' });
                addToastAction(`Congrationations`, 'You have successfully removed a magazine.', ToastStatus.Success);
            },
            (e) => {
                setLoadingOption({ ...loadingOption, isLoading: false, action: '' });
                addToastAction(`[${e.statusCode}] ${e.error}`, e.message, ToastStatus.Danger);
            }
        );
    };

    const onPreviewButtonClick = (magazine) => {
        const url = `/publish/magazine/${magazine.publishUrl || magazine.id}`;
        window.open(url, '_blank');
    };

    const updateSendingMagazineObject = (key, value) => {
        setObjSendingEmail({ ...objSendingEmail, [key]: value });
    };

    const onClickSendMagazineViaEmail = (mgzId) => {
        setSelectedMgzId(mgzId);
        updateSendingMagazineObject('visible', true);
    };

    const onSendMagazine = () => {
        if (objSendingEmail.emails.length < 1) {
            addToastAction('Warning', `Need to add at least 1 email`, ToastStatus.Warning);
            return;
        } else if (userRole !== UserRole.Agent) {
            addToastAction('Warning', 'Only Agent can send email to the clients.', ToastStatus.Warning);
            return;
        }

        setLoadingOption({ ...loadingOption, isLoading: true, action: 'Send-Email' });
        MagazineAPI.sendMagazineAPIToClients(selectedMgzId, objSendingEmail.emails).then(res => {
            updateSendingMagazineObject('visible', false);
            setLoadingOption({ ...loadingOption, isLoading: false, action: '' });
            addToastAction('Congratulations', `You have successfully sent Magazine Description email to ${objSendingEmail.emails.length} clients.`, ToastStatus.Success);
        }, (e) => {
            setLoadingOption({ ...loadingOption, isLoading: false, action: '' });
            addToastAction(`[${e.statusCode}] ${e.error}`, e.message, ToastStatus.Danger);
        });

    };

    const addEmailToSendMagazine = () => {
        if (!objSendingEmail.email) {
            addToastAction(`Warning`, 'You need enter an email.', ToastStatus.Warning);
            return;
        } else if (!validateEmail(objSendingEmail.email)) {
            addToastAction(`Warning`, 'You need enter a valid email.', ToastStatus.Warning);
            return;
        } else if (objSendingEmail.emails.includes(objSendingEmail.email)) {
            addToastAction(`Warning`, 'This email is already added.', ToastStatus.Warning);
            return;
        }
        setObjSendingEmail({ ...objSendingEmail, emails: objSendingEmail.emails.concat([objSendingEmail.email]), email: '' });
    };

    const removeEmailToSendMagazine = (email) => {
        updateSendingMagazineObject('emails', objSendingEmail.emails.filter(e => e !== email));
    };

    return (
        <main className='container'>
            <div className='banner-container'>
                <img src={Banner} alt='banner' />
                <div className='banner-description'>
                    <h1 className='banner-header'>GoHomely MgzBuilder </h1>
                    <p className='banner-paragraph'>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                        dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip
                        ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu
                        fugiat nulla pariatur. Excepteur sint occaecat cu
                    </p>
                </div>
            </div>

            <div className='create-magazine'>
                <h1 className='explore'>
                    Explore Your Magazines
                </h1>
                <div className='create-button' disabled={loadingOption.isLoading} onClick={onClickNewMagazineButton}>
                    <LoadingSpinner invisible={loadingOption.action !== 'Create'} />
                    <BsPlusCircleFill className='circle-plus' size={30} />
                    <div className='create'>Create Magazine</div>
                </div>
            </div>

            {!loadingOption.isInitialized ? <LoadingSpinner className='mx-auto' size='xl' /> : magazines.length < 1 ? (
                <Alert variant='info' className='mx-auto my-3'>
                    No magazines found. Please create your magazine.
                </Alert>
            ) : (magazines.map((magazine, index) => (
                <div className='presentation-container' key={magazine.id + index}>
                    <div className='home-image'>
                        <img src={House_1} alt='house' />
                        <div className='home-image-glass'></div>
                        <p className='home-label'>{magazine.name}</p>
                    </div>
                    <div className='presentation-description'>
                        <h1 className='title'>{magazine.name}</h1>
                        <p className='home-url'>Publish URL: <span>{magazine.publishUrl}</span></p>
                        <p className='home-description'>{magazine.description}</p>
                        <div className='buttons'>
                            <button type="button" className="btn" disabled={loadingOption.isLoading} variant='success' onClick={() => onPreviewButtonClick(magazine)}><HiEye /><p>Preview</p></button>
                            <button type="button" className="btn" onClick={() => onClickSendMagazineViaEmail(magazine.id)}><MdEmail /><p>Email</p></button>
                            <button type="button" className="btn" onClick={() => updateMgzDetail(magazine)}><BiEdit /><p>Update</p></button>
                            <button type="button" className="btn" onClick={() => history.push(`${match.url}/${magazine.id}/sections?url=${magazine.publishUrl}`)}><BsPlusCircleFill /><p>Contents</p> </button>
                            <button type="button" className="btn" onClick={(e) => onClickRemoveMagazineButton(e, magazine.id)}> <AiTwotoneDelete /><a>Delete</a> </button>
                        </div>

                    </div>
                </div>
            ))
            )}
            <Modal centered show={visibleUpdateMagazineModal} onHide={() => setVisibleUpdateMagazineModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {curMagazine.id ? 'Update Magazine' : 'Create Magazine'}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <Form.Label><b>Name</b></Form.Label>
                            <Form.Control disabled={loadingOption.isLoading}
                                placeholder='Type a magazine name'
                                value={curMagazine.name}
                                onChange={(e) => updateCurMagazine('name', e.target.value)}
                            />
                            <Form.Text className='text-muted'>
                                You should provide magazine name to identify.
                            </Form.Text>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label><b>Custom Publish URL</b></Form.Label>
                            <Form.Control disabled={loadingOption.isLoading}
                                placeholder='Type a publich url'
                                value={curMagazine.publishUrl}
                                onChange={(e) => updateCurMagazine('publishUrl', e.target.value)}
                            />
                            <Form.Text className='text-muted'>
                                You should provide this in order to protect your magazine link.
                            </Form.Text>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label><b>Description</b></Form.Label>
                            <Form.Control disabled={loadingOption.isLoading}
                                as='textarea'
                                rows={3}
                                placeholder='Type a magazine description'
                                value={curMagazine.description}
                                onChange={(e) => updateCurMagazine('description', e.target.value)}
                            />
                            <Form.Text className='text-muted'>
                                You could provide magazine descriptions.
                            </Form.Text>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button disabled={loadingOption.isLoading} variant='secondary' onClick={() => setVisibleUpdateMagazineModal(false)}>
                        Close
                    </Button>
                    <Button disabled={loadingOption.isLoading} variant='success' onClick={onSubmitMagazine}>
                        <LoadingSpinner invisible={loadingOption.action !== 'Create' && loadingOption.action !== 'Update'} />
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal centered show={objSendingEmail.visible} onHide={() => updateSendingMagazineObject('visible', false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Send Magazine Link via Email</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={(e) => e.preventDefault()}>
                        <Form.Group>
                            <Form.Label><b>Email</b></Form.Label>
                            <Form.Control
                                placeholder='Add email to send magazine to'
                                value={objSendingEmail.email}
                                onChange={(e) => updateSendingMagazineObject('email', e.target.value)}
                                onKeyPress={event => (event.key === 'Enter') && addEmailToSendMagazine()}
                            />
                            <Form.Text className='text-muted'>
                                You should enter at least 1 email.
                            </Form.Text>
                        </Form.Group>
                        <Form.Row>
                            <Col sm={12}>
                                <ListGroup className='w-100'>
                                    {objSendingEmail.emails.map((e, ei) => <ListGroup.Item className='py-2 d-flex justify-content-between align-items-center' key={ei}>
                                        <span>{e}</span>
                                        <FontAwesomeIcon className='text-danger cursor-pointer' icon={faTimes} onClick={() => removeEmailToSendMagazine(e)} />
                                    </ListGroup.Item>)}
                                </ListGroup>
                            </Col>
                        </Form.Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button disabled={loadingOption.isLoading} variant='secondary' onClick={() => updateSendingMagazineObject('visible', false)}>
                        Close
                    </Button>
                    <Button disabled={loadingOption.isLoading} variant='success' onClick={onSendMagazine}>
                        <LoadingSpinner invisible={loadingOption.action !== 'Send-Email'} />
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>

            <ConfirmPopover
                visible={visibleConfirm}
                target={confirmRef}
                placement='top'
                setVisibility={setVisibleConfirm}
                label='Are you sure to remove this magazine?'
                onConfirm={removeMagazine}
            />
        </main>
    );
}

ClientMagazines.propTypes = {};
ClientMagazines.defaultProps = {};

export default withRouter(connectToasts(connectAuthCheck(ClientMagazines)));
