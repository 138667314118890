import React from 'react';
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import {
    Switch,
    Route,
    Redirect,
    withRouter,
    Link
} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons'

import ClientMagazines from 'pages/client/magazines';
import ClientMagazineSections from 'pages/client/magazine-sections';
import { connectAuth } from 'common/redux/connects';
import { CustomSwitch } from 'components/common/switch';
import { isDarkTheme, toggleDarkTheme } from 'common/utils/theme.utils';

function AgentLayout({ match, logoutUserAction }) {

    return (<div className='vh-100 vw-100'>
        <Navbar bg='dark' variant='dark' expand='md'>
        <Navbar.Brand><Link to='/' className='text-decoration-none text-white'>Homly True Predictive Inteligence- Listing Appointment Assurance</Link></Navbar.Brand>
            <Navbar.Toggle />
            <Navbar.Collapse className='justify-content-end'>
                <Nav className='ml-auto mr-3'>
                <CustomSwitch className='my-auto' value={isDarkTheme()} onChange={e => toggleDarkTheme(e.target.checked)} />
                    <Nav.Link disabled>Dark Theme</Nav.Link>
                    <NavDropdown alignRight title={<FontAwesomeIcon className='mx-1 my-auto' icon={ faUser } size='lg' />}>
                        <NavDropdown.Item disabled>Profile</NavDropdown.Item>
                        <NavDropdown.Item disabled>Magazines</NavDropdown.Item>
                        <NavDropdown.Item disabled>Notifications</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item onClick={logoutUserAction}>Logout</NavDropdown.Item>
                    </NavDropdown>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
        <Switch>
            <Route exact path={`${match.url}/dashboard`}>
                <div>Customer Dashboard</div>
            </Route>
            <Route exact path={`${match.url}/magazines`} component={ClientMagazines} />
            <Route exact path={`${match.url}/magazines/:magazineId/sections`} component={ClientMagazineSections} />
            <Route path='/' >
                <Redirect to={`${match.url}/magazines`} />
            </Route>
        </Switch>
    </div>);
};

export default withRouter(connectAuth(AgentLayout));