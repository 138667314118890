import {
    MAGAZINE_FIRST_PAGE,
    MAGAZINE_LAST_PAGE,
    MAGAZINE_NEXT_PAGE,
    MAGAZINE_PREV_PAGE,
    MAGAZINE_SPECIFIC_PAGE,
    MAGAZINE_SET_PAGES
} from './types';

export const goToNextPage = () => ({ type: MAGAZINE_NEXT_PAGE });
export const goToPrevPage = () => ({ type: MAGAZINE_PREV_PAGE });
export const goToFirstPage = () => ({ type: MAGAZINE_FIRST_PAGE });
export const goToLastPage = () => ({ type: MAGAZINE_LAST_PAGE });
export const goToSpecificPage = (pageIndex) => ({ type: MAGAZINE_SPECIFIC_PAGE, payload: { pageIndex }});
export const setPages = (pagesCount, sectionsCount, pageSections) =>
    ({ type: MAGAZINE_SET_PAGES, payload: { pagesCount, sectionsCount, pageSections } });
