import React, { useState, useEffect, useRef } from 'react';
import { Container, Form, Modal, Button, Row, Col, Card, Alert } from 'react-bootstrap';
import { withRouter } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload, faTimes } from '@fortawesome/free-solid-svg-icons';

import { AdvertisementType } from 'common/enums/advertisements';
import { CustomSelect } from 'components/common/select';
import { connectToasts } from 'common/redux/connects';
import { ToastStatus } from 'common/enums/toast';
import { UploadFiletype } from 'common/enums/upload';

import AdvertisementAPI from 'common/api/advertisement';
import UploadAPI from 'common/api/upload';
import { ConfirmPopover } from 'components/common/popover';
import { LoadingSpinner } from 'components/common/spinner';
import { defaultLoadingOption } from 'common/constants/common';

const initAds = {
    type: AdvertisementType.Video,
    title: '', description: '', category: '',
    secondsToSkippable: -1, assets: []
};

function AdminAdsSetting({ addToastAction }) {
    const [visibleModal, setVisibleModal] = useState(false);
    const [advertisements, setAdvertisements] = useState([]);
    const [curAds, setCurAds] = useState(initAds);
    const [adsTypes, setAdsTypes] = useState([]);
    const [loadingOption, setLoadingOption] = useState(defaultLoadingOption);
    const fileInput = useRef(null);

    // Confirm Popver
    const [visibleConfirm, setVisibleConfirm] = useState(false);
    const [confirmRef, setConfirmRef] = useState(null);
    const [deletingAdsId, setDeletingAdsId] = useState('');

    useEffect(() => {
        const arrAdsTypes = [];
        for (const key in AdvertisementType) {
            if (Object.hasOwnProperty.call(AdvertisementType, key)) {
                const value = AdvertisementType[key];
                // TODO: need to add all types of ads later
                if (value === 'ADS-VIDEO') {
                    arrAdsTypes.push({ id: value, name: key });
                }
            }
        }
        setAdsTypes(arrAdsTypes);

        setLoadingOption({ ...loadingOption, isLoading: true });
        AdvertisementAPI.getAllAdvertisements().then(arrAds => {
            setLoadingOption({ ...loadingOption, isLoading: false, isInitialized: true });
            setAdvertisements(arrAds);
        }, e => {
            setLoadingOption({ ...loadingOption, isLoading: false, isInitialized: true });
            addToastAction(`[${e.statusCode}] ${e.error}`, e.message, ToastStatus.Danger);
        });
    }, []);

    const updateCurAds = (key, value) => {
        setCurAds({ ...curAds, [key]: value });
    }

    const onSubmitAds = () => {
        if (!curAds.id) {
            if (!validateAdvertisement()) {
                return;
            }
            setLoadingOption({ ...loadingOption, isLoading: true, action: 'Create' });
            AdvertisementAPI.registerAdvertisement(curAds).then(res => {
                addToastAction('Success', 'You have successfully registered a new advertisement.', ToastStatus.Success);
                setAdvertisements(advertisements.concat([{ ...curAds, id: res.id }]));
                setCurAds(initAds);
                setLoadingOption({ ...loadingOption, isLoading: false, action: '' });
                setVisibleModal(false);
            }, e => {
                setLoadingOption({ ...loadingOption, isLoading: false, action: '' });
                addToastAction(`[${e.statusCode}] ${e.error}`, e.message, ToastStatus.Danger);
            });
        } else {
            if (!validateAdvertisement()) {
                return;
            }
            setLoadingOption({ ...loadingOption, isLoading: true, action: 'Update' });
            AdvertisementAPI.updateAdvertisement(curAds).then(res => {
                addToastAction('Success', 'You have successfully updated advertisement.', ToastStatus.Success);
                setAdvertisements(advertisements.map(advertisement => {
                    if (advertisement.id !== curAds.id) { return advertisement; }
                    else { return curAds; }
                }));
                setCurAds(initAds);
                setLoadingOption({ ...loadingOption, isLoading: false, action: '' });
                setVisibleModal(false);
            }, e => {
                setLoadingOption({ ...loadingOption, isLoading: false, action: '' });
                addToastAction(`[${e.statusCode}] ${e.error}`, e.message, ToastStatus.Danger);
            });
        }
    };

    const validateAdvertisement = () => {
        // Check if empty fields
        if (!curAds.title) {
            addToastAction('Warning', 'You need to enter title.', ToastStatus.Warning);
            return false;
        } else if (!curAds.category) {
            addToastAction('Warning', 'You need to enter category.', ToastStatus.Warning);
            return false;
        } else if (curAds.assets.length < 1) {
            addToastAction('Warning', 'You need to add at least one asset.', ToastStatus.Warning);
            return false;
        }
        return true;
    }

    const onClickCreateAds = () => {
        setCurAds(initAds);
        setVisibleModal(true);
    }

    const onFileChanged = e => {
        const { files } = e.target;
        if (files.length > 0) {
            const file = e.target.files[0];
            setLoadingOption({ ...loadingOption, isLoading: true, action: '' });
            UploadAPI.uploadFile(file, UploadFiletype.Advertisement).then(fileName => {
                setCurAds({ ...curAds, assets: curAds.assets.concat(fileName) });
                setLoadingOption({ ...loadingOption, isLoading: false, action: '' });
            }, e => {
                setLoadingOption({ ...loadingOption, isLoading: false, action: '' });
                addToastAction(`[${e.statusCode}] ${e.error}`, e.message, ToastStatus.Danger);
            });
        }
    };

    const onClickRemoveAsset = (curAsset) => {
        setCurAds({ ...curAds, assets: curAds.assets.filter(asset => asset !== curAsset) })
    }

    const onClickRemoveAds = (event, adsId) => {
        setConfirmRef(event.target);
        setDeletingAdsId(adsId);
        setVisibleConfirm(true);
    }

    const onClickAddAsset = () => {
        // TODO: filter file types
        /**
         *  <input type="file" accept="image/*" /> <!-- all image types --> 
            <input type="file" accept="audio/*" /> <!-- all audio types --> 
            <input type="file" accept="video/*" /> <!-- all video types --> 
         */
        if (curAds.type === AdvertisementType.Video && curAds.assets.length >= 1) {
            addToastAction('Info', 'You can only add one asset to the Video typed advertisement.', ToastStatus.Info);
            return;
        }
        if (curAds.type === AdvertisementType.Video) {
            fileInput.current.accept = 'video/*';
        }
        fileInput.current.click();
    };

    const onClickUpdateAds = (advertisement) => {
        setCurAds(advertisement);
        setVisibleModal(true);
    }

    const onRemoveSelectedAds = () => {
        setLoadingOption({ ...loadingOption, isLoading: true, action: 'Delete' });
        AdvertisementAPI.deleteAdvertisement(deletingAdsId).then(res => {
            setAdvertisements(advertisements.filter(advertisement => advertisement.id !== deletingAdsId));
            addToastAction('Success', 'You have successfully removed an advertisement.', ToastStatus.Success);
            setLoadingOption({ ...loadingOption, isLoading: false, action: '' });
        }, e => {
            setLoadingOption({ ...loadingOption, isLoading: false, action: '' });
            addToastAction(`[${e.statusCode}] ${e.error}`, e.message, ToastStatus.Danger);
        });
        setVisibleConfirm(false);
    }

    return (<Container fluid>
        <h2 className='my-4'>Advertisements</h2>
        <Row>
            <Col sm={12} className='mb-2'>
                <Button disabled={loadingOption.isLoading} variant='success' className='float-right' onClick={onClickCreateAds}>
                    <LoadingSpinner invisible={loadingOption.action !== 'Create'} />
                    Create
                </Button>
            </Col>
            {!loadingOption.isInitialized ? <LoadingSpinner className='mx-auto' size='xl' /> :
                advertisements.length < 1 ?
                    <Alert variant='info' className='mx-auto'>No advertisements created yet.</Alert> :
                    advertisements.map((advertisement, ai) => <Col key={ai} sm={12} lg={4} className='mb-2'>
                        <Card className='w-100'>
                            <Card.Body>
                                <Card.Title className='mb-4'>
                                    <FontAwesomeIcon className='float-right text-danger cursor-pointer' icon={faTimes} onClick={(e) => onClickRemoveAds(e, advertisement.id)} />
                                </Card.Title>
                                <Card.Title><b>{advertisement.title}</b></Card.Title>
                                <Card.Subtitle className="mb-2 text-muted">Type: {advertisement.type}</Card.Subtitle>
                                <Card.Subtitle className="mb-2 text-muted">Category: {advertisement.category}</Card.Subtitle>
                                <Card.Text>{advertisement.description}</Card.Text>
                                <Card.Link className='cursor-pointer float-right' onClick={() => onClickUpdateAds(advertisement)}>Update this Ads</Card.Link>
                            </Card.Body>
                        </Card>
                    </Col>)}
        </Row>

        <Modal centered show={visibleModal} size='lg' className='p-0' onHide={() => setVisibleModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title>{curAds.id ? 'Update ' : 'Create '}Advertisement</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Group>
                        <Form.Label><b>Title</b></Form.Label>
                        <Form.Control disabled={loadingOption.isLoading} placeholder="Enter advertisement title" value={curAds.title} onChange={e => updateCurAds('title', e.target.value)} />
                        <Form.Text className="text-muted">
                            You should provide advertisement title to identify.
                        </Form.Text>
                    </Form.Group>

                    <Form.Row>
                        <Col sm={12} md={4}>
                            <CustomSelect disabled={loadingOption.isLoading} label='Type' value={curAds.type} values={adsTypes} onChange={e => updateCurAds('type', e.target.value)} />
                        </Col>
                        <Col sm={12} md={8}>
                            <Form.Group>
                                <Form.Label><b>Category</b></Form.Label>
                                <Form.Control disabled={loadingOption.isLoading} placeholder="Enter advertisement category" value={curAds.category} onChange={e => updateCurAds('category', e.target.value)} />
                                <Form.Text className="text-muted">
                                    You should provide advertisement category to identify.
                                </Form.Text>
                            </Form.Group>
                        </Col>
                    </Form.Row>

                    <Form.Group>
                        <Form.Label><b>Description</b></Form.Label>
                        <Form.Control disabled={loadingOption.isLoading} as="textarea" rows={3} placeholder="Type a Advertisement description" value={curAds.description} onChange={e => updateCurAds('description', e.target.value)} />
                        <Form.Text className="text-muted">
                            You could provide advertisement description.
                        </Form.Text>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label><b>Seconds to Skippable</b></Form.Label>
                        <Form.Control disabled={loadingOption.isLoading} type='number' placeholder="Enter seconds to be skippable" min={-1}
                            value={curAds.secondsToSkippable} onChange={e => updateCurAds('secondsToSkippable', e.target.value)} />
                        <Form.Text className="text-muted">
                            You could set seconds to skip this advertisement.
                        </Form.Text>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label><b>Assets</b></Form.Label>
                        {!loadingOption.isLoading ? <FontAwesomeIcon className='cursor-pointer float-right text-info' icon={faUpload} size='lg' onClick={onClickAddAsset} /> : null}
                        {curAds.assets.map((asset, ai) => <div key={ai} className='d-flex align-items-center justify-content-center mb-1'>
                            <Form.Control disabled value={asset} />
                            {!loadingOption.isLoading ? <FontAwesomeIcon className='cursor-pointer text-danger ml-2' icon={faTimes} size='lg' onClick={() => onClickRemoveAsset(asset)} /> : null}
                        </div>)}
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button disabled={loadingOption.isLoading} variant="secondary" onClick={() => setVisibleModal(false)}>
                    Close
                </Button>
                <Button disabled={loadingOption.isLoading} variant="success" onClick={onSubmitAds}>
                    <LoadingSpinner invisible={loadingOption.action !== 'Create' && loadingOption.action !== 'Update'} />
                    Confirm
                </Button>
            </Modal.Footer>
            <Form.File ref={fileInput} label='' className='d-none' onChange={onFileChanged} />
        </Modal>

        <ConfirmPopover visible={visibleConfirm} target={confirmRef} placement='left' setVisibility={setVisibleConfirm}
            label='Are you sure to remove this advertisement?' onConfirm={onRemoveSelectedAds} />
    </Container>
    );
};

export default withRouter(connectToasts(AdminAdsSetting));
