import React, { useRef, useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { withRouter } from 'react-router';
import { connectLoginAdvertisement } from 'common/redux/connects';

function LoginAdvertisement({ history, advertisement, loginAdvertisementFinishedAction, loginAdvertisementRefusedAction }) {
    const [visibleContinueButton, setVisibleContinueButton] = useState(false);
    const videoRef = useRef(null);

    // TODO: need to handle skip using secondsToSkippable

    useEffect(() => {
        const adsVideoEndedEventListener = () => {
            setVisibleContinueButton(true);
        }
        if (videoRef && videoRef.current) {
            videoRef.current.addEventListener('ended', adsVideoEndedEventListener);
        }
        return () => {
            if (videoRef && videoRef.current) {
                videoRef.current.removeEventListener('ended', adsVideoEndedEventListener);
            }
        };
    }, []);

    return (<Modal centered show={!!advertisement} size='lg' className='p-0' onHide={() => { }}>
        <Modal.Header>
            <Modal.Title>Take our Advertisement</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <video ref={videoRef} autoPlay preload='' playsInline poster='' className='w-100' style={{ objectFit: 'cover' }}>
                <source src={advertisement.assets[0]}/>
            </video>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="warning" onClick={loginAdvertisementRefusedAction}>Close and Refuse Login</Button>
            {visibleContinueButton ? <Button variant="success" onClick={() => loginAdvertisementFinishedAction(history)}>Continue Login</Button> : null}
        </Modal.Footer>
    </Modal>);
};

export default withRouter(connectLoginAdvertisement(LoginAdvertisement));
