/* AUTHENTICATION */
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR';
export const LOGIN_ADVERTISEMENT = 'LOGIN-ADVERTISEMENT';
export const LOGIN_ADVERTISEMENT_FINISHED = 'LOGIN-ADVERTISEMENT-FINISHED';
export const LOGIN_ADVERTISEMENT_REFUSED = 'LOGIN-ADVERTISEMENT-REFUSED';
export const VERIFY_ACCESS_TOKEN = 'VERIFY-ACCESS-TOKEN';
export const VERIFY_ACCESS_TOKEN_SUCCESS = 'VERIFY-ACCESS-TOKEN-SUCCESS';
export const VERIFY_ACCESS_TOKEN_ERROR = 'VERIFY-ACCESS-TOKEN-ERROR';
export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_ERROR = 'REGISTER_USER_ERROR';
export const LOGOUT_USER = 'LOGOUT_USER';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';

// UI Theme
export const UI_THEME_CHANGE = 'UI-THEME-CHANGE';

// Magazine View Page
export const MAGAZINE_NEXT_PAGE = 'MAGAZINE-NEXT-PAGE';
export const MAGAZINE_PREV_PAGE = 'MAGAZINE-PREV-PAGE';
export const MAGAZINE_FIRST_PAGE = 'MAGAZINE-FIRST-PAGE';
export const MAGAZINE_LAST_PAGE = 'MAGAZINE-LAST-PAGE';
export const MAGAZINE_SPECIFIC_PAGE = 'MAGAZINE-SPECIFIC-PAGE';
export const MAGAZINE_SET_PAGES = 'MAGAZINE-SET-PAGES';

// Toast
export const TOAST_ADD = 'TOAST-ADD';
export const TOAST_REMOVE = 'TOAST-REMOVE';
export const TOAST_REMOVE_ALL = 'TOAST-REMOVE-ALL';
