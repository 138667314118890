import axios from 'axios';

const NarrprAPI = {
    getPropertyWorthy: (addressDetail) => {
        const URL = 'https://www.narrpr.com/widgets/avm-widget/widget.ashx/html';
        const data = {
            Token: "661DC122-56B8-4CF1-AA7B-CF9D183FFDE5",
            Query: addressDetail,
            cbcode: "btshomly",
            ShowRprLinks: true
        };
        axios.get(URL, data).then(res => {
            console.log(res);
        }, error => {
            console.log(error);
        });
    },
}

export default NarrprAPI;
