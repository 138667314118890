import { combineReducers } from 'redux';

import authReducer from './auth-reducer';
import uiReducer from './ui-reducer';
import magazineReducer from './magazine-reducer';
import toastReducer from './toast-reducer';

const rootReducer = combineReducers({
    authReducer,
    uiReducer,
    magazineReducer,
    toastReducer
});

export default rootReducer;
