import React, { useState } from 'react';
import { Button, Form, Row } from 'react-bootstrap';
import { withRouter } from 'react-router';

import LoginAdvertisement from 'containers/advertisement/login-advertisement';
import { connectAuth } from 'common/redux/connects';
import { validateEmail } from 'common/utils/common.utils';
import { LoadingSpinner } from 'components/common/spinner';
import { Link } from 'react-router-dom';

function LoginPage({ showAdvertisement, authLoading, loginUserAction, history }) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [validationErrors, setValidationErrors] = useState({ password: '', email: '', validated: false });

    const onClickLoginButton = () => {
        const { newValidationErrors, invalid } = validateLoginForm();
        setValidationErrors({ ...newValidationErrors, validated: true });

        if (invalid) { return; }

        loginUserAction({ email, password }, history);
    };

    const validateLoginForm = () => {
        // Validate Login Form
        const newValidationErrors = { email: '', password: '' };
        let valid = true;
        if (!email) {
            newValidationErrors.email = 'Please enter an email';
            valid = false;
        } else if (!validateEmail(email)) {
            newValidationErrors.email = 'Please enter valid email';
            valid = false;
        }
        if (!password) {
            newValidationErrors.password = 'Please enter password';
            valid = false;
        }
        return { newValidationErrors, invalid: !valid };
    };

    return (<Row className='p-5 flex-column justify-content-center m-0 h-100 mx-auto' style={{ maxWidth: 700 }}>
        <h3 className='text-primary mb-3'>Welcome !</h3>
        <h3 className='text-primary mb-5'>Sign in to your account</h3>
        <Form validated={validationErrors.validated}>
            <Form.Group controlId='login-form-email'>
                <Form.Label>Email address</Form.Label>
                <Form.Control required type='email' disabled={authLoading} placeholder='Enter email' value={email}
                    onChange={e => setEmail(e.target.value)} isInvalid={!!validationErrors.email}/>
                <Form.Text className='text-muted'>
                    We'll never share your email with anyone else.
                </Form.Text>
                <Form.Control.Feedback type={!!validationErrors.email ? 'invalid' : 'valid'}>{validationErrors.email}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId='login-form-password'>
                <Form.Label>Password</Form.Label>
                <Form.Control required type='password' disabled={authLoading} placeholder='Password' value={password} onChange={e => setPassword(e.target.value)}
                    isInvalid={!!validationErrors.password} onKeyPress={event => (event.key === 'Enter') && loginUserAction({ email, password }, history)} />
                <Form.Text className='text-muted text-right'>
                    <Link disabled={!authLoading} to='/auth/forgot-password'>Fotgot password?</Link>
                </Form.Text>
                <Form.Control.Feedback type={!!validationErrors.password ? 'invalid' : 'valid'}>{validationErrors.password}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId='login-form-remember'>
                <Form.Check size='lg' type="checkbox" disabled={authLoading} label="Remember me for 7 days" />
            </Form.Group>

            <Form.Row className='justify-content-end'>
                <Button disabled={authLoading} className='float-right px-30' variant='primary' onClick={onClickLoginButton}>
                    <LoadingSpinner invisible={!authLoading}/>
                    Login
                </Button>
            </Form.Row>

            <Form.Row className='flex-direction-column'>
                <hr className='w-100 my-50' />
                <Form.Text className='text-muted mx-auto'>
                    <Link disabled={authLoading} to='/auth/register'>Register now</Link>
                </Form.Text>
            </Form.Row>
        </Form>

        {showAdvertisement ? <LoginAdvertisement /> : null}
    </Row>);
};

export default withRouter(connectAuth(LoginPage));

