import { connect } from 'react-redux';

import { loginAdvertisementFinished, loginAdvertisementRefused, loginUser, logoutUser, verifyAccessToken } from './actions/auth-actions';
import { updateTheme } from './actions/ui-actions';
import { goToFirstPage, goToLastPage, goToNextPage, goToPrevPage, goToSpecificPage, setPages } from './actions/magazine-actions';
import { addToast, removeToast } from './actions/toast-actions';

// AUTHENTICATION
const mapUserStateToProps = ({ authReducer }) => ({
    authLoading: authReducer.loading,
    user: authReducer.user,
    showAdvertisement: !!authReducer.advertisement
});
const mapAuthDispatchToProps = { loginUserAction: loginUser, logoutUserAction: logoutUser };
export const connectAuth = connect( mapUserStateToProps, mapAuthDispatchToProps );

const mapLoginAdsStateToProps = ({ authReducer }) => ({
    advertisement: authReducer.advertisement
});
const mapLoginAdsDispatchToProps = {
    loginAdvertisementFinishedAction: loginAdvertisementFinished,
    loginAdvertisementRefusedAction: loginAdvertisementRefused,
};
export const connectLoginAdvertisement = connect(mapLoginAdsStateToProps, mapLoginAdsDispatchToProps);

const mapAuthStateToProps = ({ authReducer }) => ({
    tokenVerified: !(authReducer.user === undefined),
    authenticated: !!authReducer.user,
    userRole: !authReducer.user ? null : authReducer.user.role });
const mapAuthCheckDispatchToProps = { verifyAccessTokenAction: verifyAccessToken, logoutUserAction: logoutUser };
export const connectAuthCheck = connect(mapAuthStateToProps, mapAuthCheckDispatchToProps);

// UI-THEME
const mapUIThemeStateToProps = ({ uiReducer }) => ({ theme: uiReducer.theme });
const mapUIThemeDispatchToProps = { updateTheme };
export const connectUITheme = connect( mapUIThemeStateToProps, mapUIThemeDispatchToProps );

// PRODUCTION MAGAZINE PAGE
const mapMagazineStateToProps = ({ magazineReducer }) => ({
    index: magazineReducer.index,
    pagesCount: magazineReducer.pagesCount,
    sectionsCount: magazineReducer.sectionsCount,
    pageSections: magazineReducer.pageSections
});
const mapMagazineDispathToProps = { goToFirstPage, goToLastPage, goToPrevPage, goToNextPage, goToSpecificPage };
export const connectMagazineNavigation = connect( mapMagazineStateToProps, mapMagazineDispathToProps );
const mapMagazineIndexToProps = ({ magazineReducer }) => ({
    index: magazineReducer.index,
    sectionsCount: magazineReducer.sectionsCount,
});
const mapMagazineDispathPagesToProps = { setPages, goToPrevPage, goToNextPage, goToSpecificPage };
export const connectMagazine = connect(mapMagazineIndexToProps, mapMagazineDispathPagesToProps);

// TOASTS
const mapToastsToProps = ({ toastReducer }) => ({ toasts: toastReducer.toasts });
const mapToastsDispatchToProps = { addToastAction: addToast, removeToastAction: removeToast };
export const connectToasts = connect(mapToastsToProps, mapToastsDispatchToProps);
