import React, { useEffect, useState } from 'react';
import {
    Button,
    Col,
    Form,
    Row
} from 'react-bootstrap';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';

import AuthAPI from 'common/api/auth';
import { UserGender } from 'common/enums/auth';
import { ToastStatus } from 'common/enums/toast';
import { connectToasts } from 'common/redux/connects';
import { capitalizeWord, validateEmail } from 'common/utils/common.utils';
import { BootstrapDatePicker } from 'components/common/datepicker';
import { LoadingSpinner } from 'components/common/spinner';
import { defaultLoadingOption } from 'common/constants/common';


function RegisterPage({ history, addToastAction }) {
    const [person, setPerson] = useState({
        birthday: new Date(1990, 0, 1), gender: '', firstName: '', lastName: '',
        password: '', confirmPassword: '', email: ''
    });
    const [validationErrors, setValidationErrors] = useState({
        birthday: '', gender: '', firstName: '', lastName: '',
        password: '', confirmPassword: '', email: '', validated: false
    });
    const [genders, setGenders] = useState([]);
    const [loadingOption, setLoadingOption] = useState(defaultLoadingOption);

    useEffect(() => {
        const arrGenders = [];
        for (const key in UserGender) {
            if (Object.hasOwnProperty.call(UserGender, key)) {
                const value = UserGender[key];
                arrGenders.push({
                    id: value,
                    name: capitalizeWord(value)
                });
            }
        }
        setGenders(arrGenders);
        setPerson({ ...person, gender: arrGenders[0].id })
    }, []);

    const onUpdatePerson = (value, key) => {
        setPerson({ ...person, [key]: value });
    }

    const onRegister = (event) => {
        event.preventDefault();
        event.stopPropagation();

        const { newValidationErrors, invalid } = validateRegisterForm();
        setValidationErrors({ ...newValidationErrors, validated: true });
        
        if (!invalid) {
            setLoadingOption({ ...loadingOption, action: 'Create', isLoading: true });
            AuthAPI.registerClient({
                email: person.email,
                firstName: person.firstName,
                lastName: person.lastName,
                gender: person.gender,
                birthdayTimestamp: new Date(person.birthday).getTime(),
                password: person.password
            }).then(result => {
                setLoadingOption({ ...loadingOption, action: '', isLoading: false });
                addToastAction('Congratulation', 'Thanks for creating an account. Please try to verify your email.', ToastStatus.Success);
                history.push('/auth/login');
            }, e => {
                setLoadingOption({ ...loadingOption, action: '', isLoading: false });
                addToastAction(`[${e.statusCode}] ${e.error}`, e.message, ToastStatus.Danger);
            });
        }
    };

    const validateRegisterForm = () => {
        // Validate Registration Form
        const newValidationErrors = {
            birthday: '', email: '', firstName: '', gender: '',
            lastName: '', password: '', confirmPassword: ''
        };
        let valid = true;
        if (!person.email) {
            newValidationErrors.email = 'Please enter an email';
            valid = false;
        } else if (!validateEmail(person.email)) {
            newValidationErrors.email = 'Please enter valid email';
            valid = false;
        }
        if (!person.firstName) {
            newValidationErrors.firstName = 'Please enter first name';
            valid = false;
        }
        if (!person.lastName) {
            newValidationErrors.lastName = 'Please enter last name';
            valid = false;
        }
        if (!person.gender) {
            newValidationErrors.gender = 'Please select your gender';
            valid = false;
        }
        if (!person.birthday) {
            newValidationErrors.birthday = 'Please select your birthday';
            valid = false;
        }
        if (!person.password) {
            newValidationErrors.password = 'Please enter password';
            valid = false;
        }
        if (!person.confirmPassword) {
            newValidationErrors.confirmPassword = 'Please enter password';
            valid = false;
        } else if (person.confirmPassword !== person.password) {
            newValidationErrors.confirmPassword = 'Different password detected';
            valid = false;
        }
        return { newValidationErrors, invalid: !valid };
    };

    return (<Row className='p-5 flex-column justify-content-center m-0 h-100 mx-auto' style={{ maxWidth: 700 }}>
        <h3 className='text-primary mb-3'>Welcome !</h3>
        <h3 className='text-primary mb-5'>Register your account</h3>
        <Form validated={validationErrors.validated}>
            <Form.Group>
                <Form.Label>Email address</Form.Label>
                <Form.Control disabled={loadingOption.isLoading} required type='email' placeholder='Enter Email' value={person.email} isInvalid={!!validationErrors.email}
                    onChange={e => onUpdatePerson(e.target.value, 'email')} />
                <Form.Control.Feedback type={!!validationErrors.email ? 'invalid' : 'valid'}>{validationErrors.email}</Form.Control.Feedback>
            </Form.Group>

            <Form.Row>
                <Col md={6}>
                    <Form.Group>
                        <Form.Label>First Name</Form.Label>
                        <Form.Control disabled={loadingOption.isLoading} required type='text' placeholder='Enter First Name' value={person.firstName} isInvalid={!!validationErrors.firstName}
                            onChange={e => onUpdatePerson(e.target.value, 'firstName')} />
                        <Form.Control.Feedback type={!!validationErrors.firstName ? 'invalid' : 'valid'}>{validationErrors.firstName}</Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col md={6}>
                    <Form.Group>
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control disabled={loadingOption.isLoading} required type='text' placeholder='Enter Last Name' value={person.lastName} isInvalid={!!validationErrors.lastName}
                            onChange={e => onUpdatePerson(e.target.value, 'lastName')} />
                        <Form.Control.Feedback type={!!validationErrors.lastName ? 'invalid' : 'valid'}>{validationErrors.lastName}</Form.Control.Feedback>
                    </Form.Group>
                </Col>
            </Form.Row>

            {/* Skip this Row. Not necessary to use Gender and Birthday field */}
            <Form.Row className='d-none'>
                <Col md={6}>
                    <Form.Group>
                        <Form.Label>Gender</Form.Label>
                        <Form.Control required as="select" value={person.gender} isInvalid={!!validationErrors.gender}
                            onChange={e => onUpdatePerson(e.target.value, 'gender')}>
                            {genders.map(gender => <option key={gender.id} value={gender.id}>{gender.name}</option>)}
                        </Form.Control>
                        <Form.Control.Feedback type={!!validationErrors.gender ? 'invalid' : 'valid'}>{validationErrors.gender}</Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col md={6}>
                    <Form.Group>
                        <Form.Label>Birthday</Form.Label>
                        <BootstrapDatePicker date={person.birthday} isInvalid={!!validationErrors.birthday}
                            onChange={d => onUpdatePerson(d, 'birthday')} showYearDropdown showMonthDropdown />
                        <Form.Control.Feedback type={!!validationErrors.birthday ? 'invalid' : 'valid'}>{validationErrors.birthday}</Form.Control.Feedback>
                    </Form.Group>
                </Col>
            </Form.Row>

            <Form.Group>
                <Form.Label>Password</Form.Label>
                <Form.Control disabled={loadingOption.isLoading} required type='password' placeholder='Password' value={person.password} isInvalid={!!validationErrors.password}
                    onChange={e => onUpdatePerson(e.target.value, 'password')} />
                <Form.Control.Feedback type={!!validationErrors.password ? 'invalid' : 'valid'}>{validationErrors.password}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group>
                <Form.Label>Confirm Password</Form.Label>
                <Form.Control disabled={loadingOption.isLoading} required type='password' placeholder='Confirm Password' value={person.confirmPassword} isInvalid={!!validationErrors.confirmPassword}
                    onChange={e => onUpdatePerson(e.target.value, 'confirmPassword')} />
                <Form.Control.Feedback type={!!validationErrors.confirmPassword ? 'invalid' : 'valid'}>{validationErrors.confirmPassword}</Form.Control.Feedback>
            </Form.Group>

            <Form.Row className='justify-content-end'>
                <Button disabled={loadingOption.isLoading} className='ml-3 px-3' variant='primary' onClick={onRegister}>
                    <LoadingSpinner invisible={loadingOption.action !== 'Create' }/>
                    Register
                </Button>
            </Form.Row>

            <Form.Row className='flex-direction-column'>
                <hr className='w-100 my-50' />
                <Form.Text className='text-muted mx-auto'>
                    <Link disabled={loadingOption.isLoading} to='/auth/login'>Already registered?</Link>
                </Form.Text>
            </Form.Row>
        </Form>
    </Row>);
};

export default withRouter(connectToasts(RegisterPage));
